<template>
  <!-- 支付协议管理-新增/编辑/查看 -->
  <div>
    <a-card :title="title" style="margin: 20px">
      <template slot="extra">
        <a-button type="primary" v-if="$route.query.type !== 'view'" @click="onSave">保存
        </a-button>

        <a-button @click="OnCancel">取消</a-button>
      </template>
      <!-- 表单区域 -->
      <div class="form-box">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="left">
          <div class="toptitle">付款人</div>
          <!-- 所属机构 -->
          <!--          <a-form-model-item ref="name" label="所属机构" prop="departmentId">-->
          <!--            <a-tree-select-->
          <!--              :disabled="true"-->
          <!--              v-model="form.departmentId"-->
          <!--              :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"-->
          <!--              :tree-data="treeData"-->
          <!--            >-->
          <!--            </a-tree-select>-->
          <!--          </a-form-model-item>-->
          <!--          <a-form-model-item-->
          <!--              v-if="isShowBankNUm()"-->
          <!--              label="协议编号"-->
          <!--              prop="fileNo"-->
          <!--          >-->
          <!--            <a-input-->
          <!--                disabled-->
          <!--                v-model="form.fileNo"-->
          <!--                placeholder="协议编号"-->
          <!--            ></a-input>-->
          <!--          </a-form-model-item>-->
          <!-- 电商名称 -->
          <a-form-model-item ref="name" label="付款商户" prop="mchName">
            <a-row>
              <!-- <a-form-model-item style="border: none" prop="mchName" class="mch-style">
              </a-form-model-item> -->

              <MchChooseDialog v-model="form.mchName" mchType="1" @chooseDone="onMchChosen"></MchChooseDialog>
            </a-row>
          </a-form-model-item>
          <!-- 付款账户 -->
          <a-form-model-item ref="name" label="付款钱包" prop="accountName">

            <a-radio-group placeholder="请选择" v-model="form.accountNo">
              <a-radio-button v-for="(item, ind) in payerWalletList" :key="'payerWalletList' + ind"
                :value="item.accountNo">
                <WalletInfo :account-class="item.accountClass" :account-no="item.accountNo"></WalletInfo>
              </a-radio-button>
            </a-radio-group>
            <span style="color: #ff0000; margin-left: 10px;" v-if="form.accountNo">*融资支付请使用冻结钱包</span>
          </a-form-model-item>
          <!--          <a-form-model-item ref="name" label="钱包名称" prop="accountName">-->
          <!--            <a-input disabled v-model="form.accountName" placeholder="钱包ID">-->
          <!--            </a-input>-->
          <!--          </a-form-model-item>-->
          <div class="toptitle">收款人</div>
          <a-form-model-item label="收款人分类" style="border: none" prop="orderType" class="mch-style">
            <a-radio-group v-model="form.orderType">
              <a-radio-button value="1">MCN</a-radio-button>
              <a-radio-button value="2">主播</a-radio-button>
              <a-radio-button value="3">厂家</a-radio-button>
              <a-radio-button value="4">其他</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="收款账户类型" prop="oppAccountType">
            <a-radio-group v-model="form.oppAccountType">
              <a-radio-button value="1">钱包</a-radio-button>
              <a-radio-button value="2">银行卡</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <!-- 收款账户 -->
          <a-form-model-item ref="name" :label="isShowBankNUm() ? '收款账户名称' : '收款钱包名称'" prop="oppAccountName">
            <a-row>
              <a-form-model-item style="border: none" prop="oppAccountName" class="mch-style">
                <a-input :disabled="!isShowBankNUm()" v-if="isShowBankNUm()" v-model="form.oppAccountName"
                  :placeholder="isShowBankNUm() ? '账户户名' : '钱包名称'"></a-input>

                <WalletChooseDialog v-if="!isShowBankNUm()" v-model="form.oppAccountName" @chooseDone="onPayeeChosen">
                </WalletChooseDialog>
              </a-form-model-item>
            </a-row>
          </a-form-model-item>
          <a-form-model-item style="border: none" prop="oppAccountNo" class="mch-style"
            :label="isShowBankNUm() ? '银行账号/卡号' : '钱包ID'">
            <WalletInfo v-if="!isShowBankNUm()" :account-no="form.oppAccountNo" :account-class="payeeAccountClass"
              :account-name="form.oppAccountName"></WalletInfo>
            <a-input v-if="isShowBankNUm()" v-model="form.oppAccountNo" placeholder="银行账号/卡号"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="isShowBankNUm()" label="开户行名称" prop="oppBankName">
            <a-input :disabled="isView()" v-model="form.oppBankName" placeholder="请填写开户行名称"></a-input>
            <a-button type="link" @click="isShowBankChoose = true">快速检索</a-button>
            <BankChoose v-if="isShowBankChoose" :disabled="isView()" @changeBank="changeBank"></BankChoose>
          </a-form-model-item>
          <a-form-model-item v-if="isShowBankNUm()" label="开户行行号" prop="oppBankNo">
            <a-input :disabled="isView()" v-model="form.oppBankNo" placeholder="请填写12位开户行号"></a-input>
          </a-form-model-item>
          <div class="switch_area" v-if="isShowIndustialBelt">
            <a-switch v-model="checkSwitch" @change="changeSwitch" />收款人已入驻“Chinagoods”产业带
          </div>
          <div class="qrcode_area" v-if="checkSwitch && isShowIndustialBelt">
            <div class="img_box" @click="updateQrCode">
              <canvas ref="qrcodeCanvas"></canvas>
            </div>
            <span>*请使用借款人手机号微信扫描以上二维码，注册“Chinagoods”用户</span>
          </div>
          <a-form-model-item v-if="checkSwitch && isShowIndustialBelt" label="店铺ID"
            :rules="[{ required: checkSwitch, message: '请填写店铺ID', trigger: 'blur' }]" prop="cgShopId">
            <a-input :disabled="isView()" v-model.trim="form.cgShopId" placeholder="请填写店铺ID"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="checkSwitch && isShowIndustialBelt" label="店铺名称"
            :rules="[{ required: checkSwitch, message: '请填写店铺名称', trigger: 'blur' }]" prop="cgShopName">
            <a-input :disabled="isView()" v-model.trim="form.cgShopName" placeholder="请填写店铺名称"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="checkSwitch && isShowIndustialBelt" label="店铺手机号"
                             :rules="rules.cgShopMobile" prop="cgShopMobile">
            <a-input :disabled="isView()" v-model.trim="form.cgShopMobile" type="number" placeholder="请填写店铺手机号"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="checkSwitch && isShowIndustialBelt" label="采购商名称"
                             :rules="rules.cgPayName" prop="cgPayName">
            <a-input :disabled="isView()" v-model.trim="form.cgPayName" type="text" placeholder="请填写采购商名称"></a-input>
          </a-form-model-item>
          <a-form-model-item v-if="checkSwitch && isShowIndustialBelt" label="采购商手机号"
                             :rules="rules.cgPayPhone" prop="cgPayPhone">
            <a-input :disabled="isView()" v-model.trim="form.cgPayPhone" type="number" placeholder="请填写采购商手机号"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="save-dialog">
        <a-modal title="提示" :body-style="{ textAlign: 'center' }" :visible="isShowSaveDialog" :centered="true"
          @ok="dialogHandleOk" @cancel="dialogHandleCancel">
          <p>{{ dialogMsg }}</p>
        </a-modal>
      </div>
    </a-card>
  </div>
</template>

<script>
import MchChooseDialog from "@/view/components/ChooseDialog/MchChooseDialog.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import WalletInfo from "@/view/components/WalletInfo.vue";
import BankChoose from "@/view/components/BankChoose.vue";
import { generateRandomString } from "@/utils/commonMethods";
import qrcode from "qrcode";


export default {
  name: "AddPayment",
  components: {
    BankChoose,
    WalletChooseDialog,
    MchChooseDialog,
    WalletInfo,
  },
  data() {
    const form = {
      // 所属机构
      departmentId: "",
      //渠道编号
      channelNo: "",
      // 应用编号
      appNo: "",
      //电商名称
      mchName: "",
      // 电商编号
      mchNo: "",
      // 订单类型
      orderType: "4",
      // 付款户名
      accountName: "",
      // 付款账号
      accountNo: "",
      //收款账户类型
      oppAccountType: "2",
      // 收款账号
      oppAccountNo: "",
      // 收款户名
      oppAccountName: "",
      // 收款行号
      oppBankNo: "",
      // 收款行名
      oppBankName: "",
      // 收款行名（协议编号）
      fileNo: undefined,
      // cg店铺id
      cgShopId: undefined,
      // cg店铺手机号
      cgShopMobile: undefined,
      cgShopName: undefined,
      cgPayName: undefined,
      cgPayPhone: undefined,
    };
    const rules = {
      cgShopId: [
        { required: true, message: "请填写店铺ID", trigger: "blur" },
      ],
      cgShopName: [
        { required: true, message: "请填写店铺名称", trigger: "blur" },
      ],
      cgShopMobile: [{
        required: true, message: "请输入手机号", trigger: "blur"
      },
      {
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入有效的手机号码",
        trigger: "blur",
      },
      ],
      cgPayPhone:[],
      cgPayName:[],
      departmentId: [
        { required: true, message: "请选择所属机构", trigger: "change" },
      ],
      mchName: [
        { required: true, message: "请填写电商名称", trigger: "change" },
      ],
      mchNo: [{ required: true, message: "请填写电商编号", trigger: "change" }],
      accountName: [
        { required: true, message: "请填写付款户名", trigger: "change" },
      ],
      accountNo: [
        { required: true, message: "请填写付款账号", trigger: "change" },
      ],
      oppAccountType: [
        { required: true, message: "请选择收款账户类型", trigger: "change" },
      ],
      oppAccountName: [
        { required: true, message: "请填写收款户名", trigger: "blur" },
      ],
      oppAccountNo: [
        {
          required: true, validator: (rule, value, callback) => {
            const cardRegex = new RegExp(/^\d{5,}$/)
            if (!cardRegex.test(value)) {
              callback(new Error('银行账号/卡号输入有误'))
            }
            callback()
          }, trigger: "blur"
        },
      ],
      oppBankNo: [
        {
          required: true, validator: (rule, value, callback) => {
            const cardRegex = new RegExp(/^\d{5,}$/)
            if (!cardRegex.test(value)) {
              this.form.oppBankNo = ''
              callback(new Error('开户行号输入有误'))
            }
            callback()
          }, trigger: "blur"
        },
      ],
      oppBankName: [
        { required: true, message: "请填写银行卡号", trigger: "blur" },
      ],
      fileNo: [{ required: true, message: "", trigger: "change" }],
    };
    return {
      isShowIndustialBelt: false,
      // 表单对象
      form,
      // 表单校验规则
      rules,
      payerWalletList: [], //付款方钱包
      treeData: [],
      // 列表弹框
      isShowTableDialog: false,
      //选择按钮类型
      selectType: "",
      // 保存和取消弹框
      isShowSaveDialog: false,
      // 收款人账号类型
      payeeAccountClass: undefined,
      saveOrCancel: "",
      isShowBankChoose: false,
      checkSwitch: false,
      expirationTime: 60 * 2, // 设置过期时间为2分钟后（以秒为单位）
      refreshCount: 0,
    };
  },
  computed: {
    title: function () {
      if (this.$route.query.type == "add") {
        return "支付协议管理>新增";
      } else if (this.$route.query.type == "edit") {
        return "支付协议管理>编辑";
      } else if (this.$route.query.type == "view") {
        return "支付协议管理>查看";
      } else {
        return "";
      }
    },
    isView() {
      return function () {
        return this.$route.query.type == "view";
      };
    },
    // 是否显示表单最后三项
    isShowBankNUm() {
      return function () {
        return this.form.oppAccountType == "2";
      };
    },
    dialogMsg: function () {
      if (this.saveOrCancel == "save") {
        return "是否确认添加？";
      } else {
        return "是否取消新增？取消后该页面信息将不会被保存";
      }
    },
  },
  watch: {
    checkSwitch(newVal) {
      if (newVal) {
        this.createQrCode()
      }
      if (!newVal && this.$route.query.type == 'add') {
        this.form.cgShopId = ''
        this.form.cgShopMobile = ''
        this.form.cgShopName = ''
        this.form.cgPayName = ''
        this.form.cgPayPhone = ''
      }
    }
  },
  created() {
    this.form.departmentId = Number(this.$session.getItem("companyId"));
    // 获取机构列表
    this.getCustList();
    if (this.$route.query.type == "add") {
      // 新增
    } else if (this.$route.query.type == "edit") {
      // 编辑-进入页面调用详情接口回显数据
      this.viewProtocol();
    } else {
      // 查看-进入页面调用详情接口回显数据
      this.viewProtocol();
    }
  },
  mounted() {
    if (this.$route.query.type != 'add') {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  methods: {
    //改变切换按钮的状态
    changeSwitch(e) {
      if (e) {
        this.createQrCode()
      }
    },
    createQrCode() {
      let that = this
      this.$nextTick(() => {
        const canvasDiv = that.$refs.qrcodeCanvas;
        const options = {
          width: 200,
          height: 200,
        };
        const expirationTimestamp = this.getExpireTime();
        const uniqueIdentifier = this.getUniqueCode();
        const url = 'https://sourl.cn/pkMPpA'
        //二维码地址添加唯一的标识，保证每次刷新
        const urlWithExpire = `${url}?expiration=${expirationTimestamp}&uniqueIdentifier=${uniqueIdentifier}`
        qrcode.toCanvas(canvasDiv, urlWithExpire, options, (error) => {
          if (error) {
            this.$message.error("无法获取二维码，请联系管理员")
          }
        });

      })
    },
    // 点击刷新二维码
    updateQrCode() {
      this.refreshCount++
      this.createQrCode()
    },
    // 获取过期时间
    getExpireTime() {
      return Math.floor(Date.now() / 1000) + this.expirationTime
    },
    // 获取url唯一标识
    getUniqueCode() {
      return this.refreshCount
    },
    changeBank(obj) {
      this.form.oppBankNo = obj.value
      this.form.oppBankName = obj['option'].componentOptions.children[0].text
      this.$nextTick(() => {
        this.$refs.ruleForm.validateField('oppBankName', 'oppBankNo');
      });
    },
    // 处理弹框自定义事件
    onMchChosen(mch) {
      this.checkSwitch = false
      this.form.departmentId = mch.departmentId;
      this.form.mchName = mch.mchName;
      this.form.mchNo = mch.mchNo;
      this.form.channelNo = mch.channelNo;
      this.form.appNo = mch.appNo;
      this.form.accountName = mch.accountName;
      this.form.fileNo = this.form.fileNo ?? ("STZF" + "-" + mch.mchNo + "_" + generateRandomString(4));
      // this.form.accountNo = mch.accountNoA1;
      this.form.accountNo = mch.accountNoA2;
      this.payerWalletList = [
        {
          accountNo: mch.accountNoA1,
          accountClass: "0"
        },
        {
          accountNo: mch.accountNoA2,
          accountClass: "6"
        },
      ];
      //如果是代销类或者是代销特殊类电商，显示供应商信息
      if (mch.goodsType === '40' || mch.goodsType === '41') {
        this.isShowIndustialBelt = true
      } else {
        this.isShowIndustialBelt = false
      }
    },
    onPayeeChosen(wallet) {
      this.form.oppAccountName = wallet.accountName;
      this.form.oppAccountNo = wallet.accountNo;
      this.payeeAccountClass = wallet.accountClass
    },
    // 获取所属机构下拉框数据
    async getCustList() {
      let data = {
        username: this.$session.getItem("username"),
      };
      let res = await this.api.agencyList(data);
      let trees = [];
      trees.push(res.data);
      this.treeData = this.getResettingTrees(trees);
    },
    //重构树形结构方法
    getResettingTrees(trees) {
      if (trees.length == 0) {
        return [];
      }
      let res = [];
      trees.forEach((tree) => {
        res.push({
          title: tree.coreDepartment.name,
          value: tree.coreDepartment.id,
          key: tree.coreDepartment.id,
          children:
            tree.children && tree.children.length > 0
              ? this.getResettingTrees(tree.children)
              : [],
        });
      });
      return res;
    },
    // 保存按钮
    onSave() {
      this.saveOrCancel = "save";
      // 打开弹框
      this.isShowSaveDialog = true;
    },
    // 取消按钮
    OnCancel() {
      if (this.$route.query.type == "view") {
        this.$router.back(-1);
      } else {
        this.saveOrCancel = "cancel";
        this.isShowSaveDialog = true;
      }
    },
    // 保存和取消弹框里的确定事件
    dialogHandleOk() {
      // 关闭弹框
      this.isShowSaveDialog = false;

      if (this.form.oppAccountType === "1") {
        this.form.oppBankNo = "313338707013"
        this.form.oppBankName = "浙江稠州商业银行"
      }

      if (this.saveOrCancel == "save") {
        //校验
        this.onSubmit();
      } else {
        // 跳转到列表页
        this.$router.push('/orders/PaymentProtocolManage');
      }
    },
    // 保存和取消弹框里的取消事件
    dialogHandleCancel() {
      this.isShowSaveDialog = false;
    },
    // 新增或者编辑接口
    async addAndEditProtocol() {
      let data = JSON.parse(JSON.stringify(this.form));
      if (this.$route.query.type == "add") {
        let res1 = await this.api.addProtocolApi(data);
        if (res1.code == 200) {
          // 成功之后跳转到列表页
          this.$router.push({
            path: '/orders/PaymentProtocolManage',
            query: {
              fromAddPage: true
            }
          });
          this.$message.success(res1.info);
        } else {
          return this.$message.error(res1.info);
        }
      } else if (this.$route.query.type == "edit") {
        data.synch = '0'
        // if (!this.checkSwitch) {
        //   data.cgShopId = ''
        //   data.cgShopName = ''
        //   data.cgShopMobile = ''
        // }
        let res2 = await this.api.updateProtocolApi(data);
        if (res2.code == 200) {
          // 成功之后跳转到列表页
          this.$router.push({
            path: '/orders/PaymentProtocolManage',
            query: {
              fromEditPage: true
            }
          });
          this.$message.success(res2.info);
        } else {
          return this.$message.error(res2.info);
        }
      }
    },
    // 修改接口
    updateProtocol() {
      let data = JSON.parse(JSON.stringify(this.form));
      let res = this.api.updateProtocolApi(data);
      if (res.code == 200) {
        // 成功之后跳转到列表页
        this.$router.push('/orders/PaymentProtocolManage');
        this.$message.success(res.info);
      } else {
        return this.$message.error(res.info);
      }
      console.log(res);
    },
    // 查看详情接口
    async viewProtocol() {
      // console.log(this.$route.query.id, 'id')
      let data = {};
      data.id = this.$route.query.id;
      let res = await this.api.viewProtocolApi(data);
      if (res.code == 200) {
        // console.log(res.data, 'data')
        this.form = res.data;
        if (this.form.cgShopId) {
          this.checkSwitch = true
          this.updateQrCode()
        }
        await this.getMchData()
        // console.log(this.payerWalletList, 'payerWalletList');
      } else {
        return this.$message.error(res.info);
      }
    },
    //编辑或者查看时 拿到电商编号去获取该电商下的账户，来回显
    async getMchData() {
      let paramsData = {
        pageNo: 1,
        pageSize: 10,
        mchNo: this.form.mchNo
      }
      if (!this.form.mchNo) return
      let res = await this.api.mchshopsApi(paramsData)
      if (res.code == 200) {
        // console.log(res, 'mchData');
        let mchData = res.data.records[0]
        this.payerWalletList = [
          {
            accountNo: mchData.accountNoA1,
            accountClass: "0"
          },
          {
            accountNo: mchData.accountNoA2,
            accountClass: "6"
          },
        ];
        if (mchData.goodsType === '40' || mchData.goodsType === '41') {
          this.isShowIndustialBelt = true
        } else {
          this.isShowIndustialBelt = false
        }
      }

    },
    onSubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        // console.log(valid, 'valid');
        if (valid) {
          // 校验成功调用保存或者编辑接口
          await this.addAndEditProtocol();
          // 跳转到客户管理页面
        } else {
          console.log("error submit!!");
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.form-box {
  .toptitle {
    font-weight: 600;
    margin: 10px 0;
  }

  .switch_area {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-weight: 600;

    .ant-switch {
      margin-right: 5px;
    }
  }

  .qrcode_area {
    display: flex;
    align-items: center;

    span {
      color: red;
    }

    .img_box {
      cursor: pointer;
      width: 200px;
      height: 200px;
    }
  }

  /deep/ .ant-select-selection--single {
    width: 200px;
  }

  /deep/ .ant-form-item {
    display: flex;
    margin-bottom: 0;

    .mch-style {
      .ant-form-item-control-wrapper {
        border: none;
        padding: 5px 0 0 0;
        margin-right: 15px;


      }
    }

    .ant-form-item-label {
      width: 260px;
      background-color: rgba(242, 242, 242, 1);
      padding-left: 20px;
      display: flex;
      align-items: center;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
      border: 1px solid rgba(242, 242, 242, 1);
      padding: 5px 40px;

      .ant-form-item-children {
        display: flex;
      }

      .ant-input,
      .el-input__inner {
        max-width: 200px;
      }

      .ant-select {
        width: 200px;
      }

      textarea {
        resize: none;
        width: 610px !important;
        height: 137px;
      }
    }
  }
}
</style>
