<template>
  <!-- 订单支付列表页 -->
  <div>
    <a-card title="订单支付" style="margin: 20px">
      <!-- 头部筛选区域 -->
      <div class="selected-box">
        <div class="selected-box-item">
          <span class="left-item">机构</span>
          <DepartmentChoose :department-code.sync="selectedData.departmentCode"
                            @change="payOrdersList"></DepartmentChoose>
        </div>
        <div class="selected-box-item">
          <span class="left-item">支付时间</span>
          <a-range-picker v-model.trim="selectedRange" valueFormat="YYYY-MM-DD"/>
        </div>
        <div class="selected-box-item">
          <span class="left-item">支付结果</span>
          <a-select placeholder="请选择支付结果" v-model.trim="selectedData.payStatus" allowClear>
            <a-select-option value="1">
              <a-tag color="green">成功</a-tag>
            </a-select-option>
            <a-select-option value="2">
              <a-tag color="red">失败</a-tag>
            </a-select-option>
          </a-select>
        </div>
        <div class="selected-box-item">
          <span class="left-item">支付方式</span>
          <a-select placeholder="请选择支付方式" v-model.trim="selectedData.payWay" allowClear>
            <a-select-option value="3">
              <a-tag color="blue">余额支付</a-tag>
            </a-select-option>
            <a-select-option value="2">
              <a-tag color="purple">融资支付</a-tag>
            </a-select-option>
            <a-select-option value="4">
              <a-tag color="brown">清分支付</a-tag>
            </a-select-option>
          </a-select>
        </div>
        <div class="selected-box-item">
          <span class="left-item">下单状态</span>
          <a-select placeholder="请选择下单状态" allowClear v-model="selectedData.cgOrderStatus">
            <a-select-option value="0">
              <a-tag color="gray">未下单</a-tag>
            </a-select-option>
            <a-select-option value="1">
              <a-tag color="green">下单成功</a-tag>
            </a-select-option>
            <a-select-option value="2">
              <a-tag color="red">下单失败</a-tag>
            </a-select-option>

          </a-select>
        </div>

        <div class="selected-box-item">
          <span class="left-item">商户名称</span>
          <a-input v-model.trim="selectedData.mchName" placeholder="请输入商户名称"></a-input>
        </div>
        <div class="selected-box-item">
          <span class="left-item">商户编号</span>
          <a-input v-model.trim="selectedData.mchNo" placeholder="请输入商户编号"></a-input>
        </div>
        <div class="selected-box-item" style="display: block">
          <a-button type="primary" icon="search" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset" >重置</a-button>
          <a-button type="link" @click="toExport" icon="export">导出</a-button>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="table-box">
        <div class="table-box-handle">
          <!--          <a-button type="primary" @click="viewBtn" v-if="$authority('paymentView')">查看</a-button>-->
          <a-button type="primary" @click="addPayBtn" icon="transaction" v-if="$authority('newPayment')">去支付</a-button>
<!--          <a-button type="primary" @click="toOrder" v-if="$authority('toOrderBtn')">去下单</a-button>-->
        </div>
        <div class="table-main">
          <a-table size="small" :scroll="{ x: 2300, }" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :customRow="clickrow" :columns="columns" :data-source="data" :pagination="false"
                   :rowKey="(record, index) => `${record.id}`">
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(selectedData.pageNo) == 1" @click="Homepage">
          首页
        </a-button>
        <a-pagination v-model.trim="selectedData.pageNo" :page-size-options="pageSizeOptions" :total="total"
                      show-size-changer :page-size="selectedData.pageSize" @change="pagechange"
                      @showSizeChange="onShowSizeChange"
                      :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage"
                  :disabled="selectedData.pageNo == Math.ceil(Number(total) / Number(selectedData.pageSize))"
                  class="Trailingpage">
          尾页
        </a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import {message} from 'ant-design-vue'
import Balance from '@/view/components/Balance.vue'
import WalletInfo from '@/view/components/WalletInfo.vue'
import OrderStatus from "@/view/Orders/PaymentOrders/components/OrderStatus.vue"
import authority from "@/utils/authority";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

export default {
  name: 'PaymentOrders',
  props: {},
  components: {DepartmentChoose, Balance, WalletInfo, OrderStatus},
  data() {
    const columns = [
      {
        title: 'No',
        customRender: (text, record, index) =>
            `${Number(this.selectedData.pageNo - 1) *
            Number(this.selectedData.pageSize) +
            Number(index + 1)
            }`,
        align: 'center',
        key: 'sort',
        width: 40,
      },

      {
        title: '方式',
        dataIndex: 'payWay',
        key: 'payWay',
        align: 'center',
        width: 50,
        customRender: (text) => {
          if (text == '2') {
            return <a-tag color="purple">融资</a-tag>
          } else {
            return <a-tag color="blue">余额</a-tag>
          }
        },
      },
      {
        title: '支付时间',
        dataIndex: 'payTime',
        key: 'payTime',
        align: 'center',
        width: 170,
        ellipsis: true,
        customRender: (text) => {
          return text.split('T').join(' ')
        },
      },
      {
        title: '订单编号',
        dataIndex: 'orderNo',
        key: 'orderNo',
        align: 'center',
        width: 240,
        ellipsis: true,
      },

      {
        title: '付款人',
        dataIndex: 'accountName',
        key: 'accountName',
        align: 'center',
        ellipsis: true,
        width: 240,
        customRender: (text) => {
          // console.log(text)
          return text == null ? '---' : text
        },
      },
      {
        title: '付款账号',
        dataIndex: 'accountNo',
        key: 'accountNo',
        align: 'center',
        width: 220,
        customRender: (text, row) => {
          return <WalletInfo accountNo={row.accountNo} accountName={row.accountName} accountClass="0"></WalletInfo>
        },
      },
      {
        title: '商户编号',
        dataIndex: 'mchNo',
        key: 'mchNo',
        align: 'center',
        width: 240,

      },
      {
        title: '商户',
        dataIndex: 'mchName',
        key: 'mchName',
        align: 'center',
        width: 240,
      },
      {
        title: '支付金额',
        dataIndex: 'payAmount',
        key: 'payAmount',
        align: 'center',
        width: 160,
        // customRender: (text) => {
        //   return Number(text).toFixed(2)
        // }
        customRender: (text) => {
          return <Balance amount={text} dcflag="c"></Balance>
        }
      },
      {
        title: '收款人',
        dataIndex: 'oppAccountName',
        key: 'oppAccountName',
        align: 'center',
        ellipsis: true,
        width: 220,
        customRender: (text) => {
          // console.log(text)
          return text == null ? '---' : text
        },
      },
      {
        title: '收款账号',
        dataIndex: 'oppAccountNo',
        key: 'oppAccountNo',
        align: 'center',
        width: 240,
        customRender: (text, row) => {
          return <WalletInfo accountNo={row.oppAccountNo} accountName={row.oppAccountName}
                             isWallet={row.oppAccountType == 1} bankName={row.oppBankName}
                             bankNo={row.oppBankNo}></WalletInfo>
        },
      },
      {
        title: '支付状态',
        dataIndex: 'payStatus',
        key: 'payStatus',
        align: 'center',
        width: 80,
        customRender: (text, row) => {
          if (text === "1") {
            return <a-tag color="green">成功</a-tag>
          } else {
            return <a-tooltip title={row.payResult}>
              <a-tag color="red">失败</a-tag>
            </a-tooltip>
          }
        },
      },

      {
        title: '下单状态',
        dataIndex: 'cgOrderStatus',
        key: 'cgOrderStatus',
        align: 'center',
        width: 80,
        customRender: (text, row) => {
          if (text === "0") {
            return <OrderStatus orderStatus={text}
                                retry={this.$authority('toOrderBtn')}
                                retryAction={()=>this.toOrder(row.orderNo)}></OrderStatus>
          } else if (text === "1") {
            return <OrderStatus orderStatus={text}></OrderStatus>
          } else if (text === "2") {
            return <OrderStatus orderStatus={text} reason={row.cgOrderFailReasons}
                                retry={this.$authority('toOrderBtn')}
                                retryAction={()=>this.toOrder(row.orderNo)}></OrderStatus>
          }

        },
      },
      // {
      //   title: '订单类型',
      //   dataIndex: 'orderType',
      //   key: 'orderType',
      //   align: 'center',
      //   width: 100,
      //   customRender: (text) => {
      //     // console.log(text, '00')
      //     if (text == '1') {
      //       return '支付MCN'
      //     } else if (text == '2') {
      //       return '支付主播'
      //     } else if (text == '3') {
      //       return '支付厂家'
      //     } else if (text == '4') {
      //       return '其他'
      //     } else {
      //       return '--'
      //     }
      //   },
      // },
      // {
      //   title: '应用编号',
      //   dataIndex: 'appNo',
      //   key: 'appNo',
      //   align: 'center',
      // },
      // {
      //   title: '渠道',
      //   dataIndex: 'channelNo',
      //   key: 'channelNo',
      //   align: 'center',
      //   width: 60,
      // },
      // {
      //   title: '备注',
      //   dataIndex: 'remark',
      //   key: "remark",
      //   align: "center",
      //   ellipsis: true,
      // },
      {
        title: '机构',
        dataIndex: 'departmentId',
        key: 'departmentId',
        align: 'center',
        ellipsis: true,

        customRender: (text) => {
          // console.log(text)
          return this.findItemById(this.institutiontrees, text)
        },
      },
      ...(authority('mchPayFee') ? [
        {
          title: '应收服务费(￥)',
          dataIndex: 'receivableFee',
          key: 'receivableFee',
          align: 'center',
          width: 160,
          customRender: (text) => {
            return <Balance amount={text} dcflag="c"></Balance>
          }
        },
        {
          title: '实收服务费(￥)',
          dataIndex: 'actualReceivedFee',
          key: 'actualReceivedFee',
          align: 'center',
          width: 160,
          customRender: (text) => {
            return <Balance amount={text} dcflag="c"></Balance>
          }
        },
        {
          title: '服务费状态',
          dataIndex: 'received',
          key: 'received',
          align: 'center',
          width: 160,
          customRender: (text, record) => {
            let content;
            if (text === '1') {
              content = <span style={{color: 'green'}}>成功</span>;
            } else if (text === '0') {
              content = (
                  <div>
                    <span style={{color: 'red'}}>失败</span>
                    {record.receivableFee > 0 && (
                        <a-button
                            type="link"
                            onClick={() => this.retryServiceFee(record)}
                            style={{marginLeft: '8px'}}
                        >
                          <a-icon type="reload"/>
                        </a-button>
                    )}
                  </div>
              );
            } else if (text === '-1') {
              content = (
                  <div>
                    <span style={{color: 'orange'}}>未收取</span>
                    {record.receivableFee > 0 && (
                        <a-button
                            type="link"
                            onClick={() => this.retryServiceFee(record)}
                            style={{marginLeft: '8px'}}
                        >
                          <a-icon type="reload"/>
                        </a-button>
                    )}
                  </div>
              );
            } else {
              content = <span style={{color: 'gray'}}>未知状态</span>;
            }

            return <div>{content}</div>;
          }
        },


      ] : [])
    ]

    const data = []
    const selectedData = {
      payFee: false,
      pageNo: 1,
      pageSize: 10,
      // 机构ID
      // departmentId: this.$session.getItem("companyId"),
      departmentCode: '',
      // 订单类型
      orderType: undefined,
      // 支付方式
      payWay: undefined,
      // 支付状态
      payStatus: "1",
      // 电商名称
      mchName: '',
      // 电商编号
      mchNo: '',
      // 开始时间
      startTime: '',
      // 结束时间
      endTime: '',
      // 下单状态
      cgOrderStatus: undefined
    }
    return {
      // 机构数据
      treeData: [],
      institutiontrees: {},
      // 绑定时间范围
      selectedRange: [],
      // 表格数据
      data,
      columns,
      selectedRowKeys: [],
      // 筛选参数
      selectedData,
      // 总数
      total: 0,
      //分页大小
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      selectCustData: [],
      // 当前row
      currentRowKey: null,
    }
  },
  computed: {},
  watch: {},
  created() {
    // 获取列表
    if (this.$authority('newPayment')) {
      this.addPayBtn()
    }
    this.payOrdersList()
  },
  beforeDestroy() {

    // 页面销毁就重置筛选数据
    this.selectedData = {
      pageNo: 1,
      pageSize: 10,
      // 机构ID
      departmentCode: this.$session.getItem('companyCode'),
      // 订单类型
      orderType: undefined,
      // 支付方式
      payWay: undefined,
      // 支付状态
      payStatus: "1",
      // 电商名称
      mchName: '',
      // 电商编号
      mchNo: '',
      // 开始时间
      startTime: '',
      // 结束时间
      endTime: '',
      // 下单状态
      cgOrderStatus: undefined
    }
  },
  methods: {
    findItemById(dataObj, idToFind) {
      // console.log(dataObj, idToFind)
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      else if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },
    // 获取列表函数
    async payOrdersList() {
      if (this.selectedRange.length != 0) {
        this.selectedData.startTime = this.selectedRange[0] + " 00:00:00"
        this.selectedData.endTime = this.selectedRange[1] + " 23:59:59"
      }
      let data = JSON.parse(JSON.stringify(this.selectedData))

      let res = await this.api.payOrdersApi(data)
      if (res.code == 200) {
        this.data = res.data.records
        this.selectedData.pageNo = res.data.current
        this.selectedData.pageSize = res.data.size
        this.total = res.data.total
      } else {
        this.$message.error(res.info)
      }
    },
    // 查询
    handleQuery() {
      this.selectedRowKeys = []
      this.selectedData.pageNo = 1
      this.payOrdersList()
    },
    // 重置
    handleReset() {
      this.selectedData = {
        pageNo: 1,
        pageSize: 10,
        // 机构ID
        departmentCode: '',
        // 订单类型
        orderType: '',
        // 支付方式
        payWay: '',
        // 电商名称
        mchName: '',
        // 电商编号
        mchNo: '',
        // 开始时间
        startTime: '',
        // 结束时间
        endTime: '',
        // 下单状态
        cgOrderStatus: undefined
      }
      this.selectedRange = []
      this.payOrdersList()
    },
    // 改变pagesize的事件函数
    onShowSizeChange(current, pageSize) {
      // console.log(current);
      // console.log(pageSize);
      this.selectedData.pageNo = current
      this.selectedData.pageSize = pageSize
      this.payOrdersList()
    },
    // 改变当前页的事件
    handleChangePage(page) {
      this.selectedData.pageNo = page
      this.payOrdersList()
    },
    pagechange(val) {
      this.selectedData.pageNo = val
      this.payOrdersList()
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.selectedData.pageNo = 1,
          this.payOrdersList()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.selectedData.pageNo = Math.ceil(Number(this.total) / Number(this.selectedData.pageSize))
      this.payOrdersList()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 选中行的回调
    onSelectChange(val, row) {
      this.selectedRowKeys = val
      this.selectCustData = row
      // this.data.forEach((item, index) => {
      //   if (this.selectedRowKeys.indexOf(item.id) != -1) {
      //     this.selectCustData.push(this.data[index])
      //   }
      // })
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys.push(id)
              this.selectCustData.push(record)
              // console.log(this.selectCustData, 'this.selectCustData');
            } else {

              this.selectedRowKeys.splice(index, 1)
              this.selectCustData.splice(index, 1)
            }
          }
        }
      }
    },
    // 查看跳转
    viewBtn() {
      if (this.selectCustData.length != 1) {
        return message.warning('只能选中一条数据!')
      } else {
        this.selectCustData.forEach((item) => {
          // console.log(item, 'item')
          const {href} = this.$router.resolve({
            path: '/orders/PaymentOrders/AddPaymentOrders',
            query: {
              id: item.id,
              type: 'view',
            },
          })
          window.open(href, item.id)
        })
      }
    },
    // 新增支付
    addPayBtn() {
      // 不用router，防止缓存等问题
      // window.location.href = '/orders/PaymentOrders/AddPaymentOrders?type=add'
      this.$router.push({
            name: 'AddPaymentOrders',
            query: {}
          }
      )
    },
    // 去下单
    async toOrder(orderNo) {
      // if (this.selectCustData.length != 1) {
      //   return message.warning('只能选中一条数据!')
      // }
      // if (this.selectCustData[0].cgOrderStatus === '1') {
      //   return this.$message.warning("您已成功下单！")
      // }
      // let orderNo = this.selectCustData[0].orderNo
      let res = await this.api.orderSyncApi(orderNo)
      if (res.code === 200) {
        this.$message.success(res.info)
        // 获取列表
        this.payOrdersList()
        this.selectCustData = []
        this.selectedRowKeys = []
      } else {
        this.$message.error(res.info)
      }
    },
    async toExport() {
      let data = JSON.parse(JSON.stringify(this.selectedData))
      delete data.pageNo
      delete data.pageSize
      let res = await this.api.orderExportApi(data)
      this.$uploadfile.ExcelDownload('订单支付', res)
    },
    async retryServiceFee(record) {
      this.api.retryServiceFeeApi([record.id]).then(res => {
        if (res.code == 200) {
          this.$message.success('重试成功')
          this.payOrdersList()
        } else {
          this.$message.error(res.info)
        }
      })

    }
  },

}
</script>

<style lang="less" scoped>
.selected-box {
  display: flex;
  flex-wrap: wrap;

  /deep/ .selected-box-item {
    margin: 0 20px 20px 0;
    width: 280px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-item {
      margin-right: 15px;
    }

    .ant-input {
      width: 200px;
    }

    .ant-select-selection--single {
      width: 200px;
    }
  }
}

.table-box {
  .table-box-handle {
    margin-bottom: 20px;
  }
}

.paginationBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
</style>
