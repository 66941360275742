<template>
  <div>
    <a-card title="账户列表管理" style="margin: 20px">
      <div class="Walletbox">
        <!-- !表单区域 -->
        <div class="formbox">
          <!-- 所属机构 -->
          <div class="inputbox">
            <div class="lefttitle">所属机构</div>
            <!-- 所属机构 -->
            <DepartmentChoose :department-code.sync="form.departmentCode" @change="getcustaccountPage"></DepartmentChoose>
          </div>
          <!-- 客户编号 -->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">客户编号</div>-->
          <!--            <a-input v-model.trim="form.custnum" type="number" placeholder="请输入客户编号" />-->
          <!--          </div>-->

          <!-- 账号 -->
          <div class="inputbox">
            <div class="lefttitle">钱包编号</div>
            <a-input type="number" controls="false" v-model.trim="form.accountNo" placeholder="请输入客户账号"/>
          </div>
          <!-- 客户名称 -->
          <div class="inputbox">
            <div class="lefttitle">客户名称</div>
            <a-input v-model.trim="form.accountName" placeholder="请输入客户名称"/>
          </div>
          <!-- 客户类型 -->
          <div class="inputbox">
            <div class="lefttitle">客户类型</div>
            <a-select v-model.trim="form.accountProperty" placeholder="请选择客户类型" style="width:200px"
                      :allowClear="true">
              <a-select-option value="1">个人</a-select-option>
              <a-select-option value="2">企业</a-select-option>
            </a-select>
          </div>
          <!-- 客户证件号 -->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">证件号</div>-->
          <!--            <a-input v-model.trim="form.certnum" placeholder="请输入证件号" />-->
          <!--          </div>-->
          <!--          &lt;!&ndash; 户名 &ndash;&gt;-->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">户名</div>-->
          <!--            <a-input placeholder="请输入户名" v-model.trim="form.accountName" />-->
          <!--          </div>-->
          <!-- 渠道编号 -->
          <div class="inputbox">
            <div class="lefttitle">渠道</div>
            <a-select v-model.trim="form.channelNo" placeholder="请选择渠道" style="width:200px" :allowClear="true">
              <a-select-option v-for="(item,index) in channelList" :key="index" :value="item.channelNo">{{
                  item.channelName
                }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 应用编号 -->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">应用编号</div>-->
          <!--            <a-input type="number" v-model.trim="form.appNo" placeholder="请输入应用编号" />-->
          <!--          </div>-->
          <!-- 手机号 -->
          <div class="inputbox">
            <div class="lefttitle">手机号</div>
            <a-input type="number" v-model.trim="form.mobile" placeholder="请输入手机号"/>
          </div>
          <!-- 查询 -->

          <a-button class="inquire" @click="search" type="primary">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>

        <!-- 开户按钮区域 -->
        <div class="Controls">
          <a-button-group>
          <!-- 开户 -->
            <a-button @click="Openanaccount" icon="folder-open" class="inquire" type="normal" v-if="$authority('openAnAccount')">开户</a-button>
            <a-button @click="openAccSubDailogClick" icon="folder-open" class="inquire" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('openAccSub')">开分户</a-button>
            <a-button @click="editAccount" icon="edit" class="inquire" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('editAccountAuth')">变更</a-button>
            <a-button @click="bindBankCard" icon="credit-card" class="inquire" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('bindBankAuth')">绑卡</a-button>
            <a-button @click="openAccView" class="inquire" icon="eye" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('openAccView')">查看</a-button>
            <a-popover>
              <a-button @click="Loanapplication" class="inquire" icon="bank"
                        :disabled="!selectedRows?.accountNo"
                        v-if="$authority('loanApplicationF')">授信</a-button>
              <template #content>
                申请贷款
              </template>
            </a-popover>

          </a-button-group>
          <a-button @click="view" icon="clock-circle" class="inquire" type="primary"
                    :disabled="!selectedRows?.accountNo"
                    v-if="$authority('Accountview')">交易明细</a-button>
          <a-popover>
            <template slot="content">
              余额同步
            </template>
            <a-button @click="Balancesynchronization" icon="sync" class="inquire" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('balanceSynchronization')"></a-button>
          </a-popover>

          <a-button-group style="float:right">
            <!-- 余额同步 -->
            <a-button @click="Marginpayment" class="inquire"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('marginPayment')">保证金缴纳</a-button>
            <!-- 保证金释放 -->
            <a-button @click="Marginrelease" class="inquire"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('marginRelease')">保证金释放</a-button>
            <a-button @click="paymentAuth" class="inquire"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('PaymentAuth')">小额打款认证</a-button>
          </a-button-group>

        </div>

        <!-- 表格 -->
        <!-- table 区域 -->

        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" size="small" :scroll="{ x: 2100, }" :rowKey="(record, index) => `${record.id}`"
                     :row-selection="{
                selectedRowKeys: selectedRowKeys,
                hideDefaultSelections: true,
                onChange: onSelectChange,
              }" :columns="columns" @change="changeSorter" :customRow="clickrow" :data-source="data"
                     :pagination="false">
            </a-table>
          </div>
        </div>
        <!-- 分页部分 -->
        <div class="paginationBox">
          <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
            首页
          </a-button>
          <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
                        show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
                        :item-render="itemRender">

          </a-pagination>
          <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
                    class="Trailingpage">
            尾页
          </a-button>
        </div>
      </div>

    </a-card>
    <!-- 开户弹窗 -->
    <a-modal title="选择开户类型" :maskClosable="true" @cancel="Openanaccountvisible = false" :centered="true"
             :visible="Openanaccountvisible" :footer="null">
      <div class="Openanaccount">
        <!--个人开户  -->

        <div class="personage" @click="Individualaccountopening">个人开户</div>

        <!-- 企业开户 -->
        <div class="firm" @click="CorporateAccountOpening">企业开户</div>
        <div class="personage" v-if="$authority('innerAccOpen')" @click="innerAccountOpening">内部户开户</div>

      </div>
    </a-modal>
    <a-modal
        title="Title"
        :visible="openAccSubDailog"
        :confirm-loading="false"
        @ok="openAccSub"
        @cancel="openAccSubCancel"
    >
      <p> 是否确定开户</p>
    </a-modal>
    <!-- 保证金缴纳弹层  和保证金释放-->
    <a-modal style="width: 500px;" :maskClosable="true" :title="Securitydeposittype == 0 ? '保证金缴纳' : '保证金释放'"
             :centered="true" @click="Balancesynchronization" :visible="Securitydepositvisible"
             @cancel="Securitydepositvisible = false">
      <!-- 保证金缴纳 -->
      <div v-if="Securitydeposittype == 0" class="Securitydeposit">
        <!-- 用户信息缴纳 -->
        <div v-if="Securitydeposittype == 0" class="userinfo">
          <!-- 账号 -->
          <div class="Account number">
            <span> 账号： </span> <span>{{ selectedRows?.accountNo }}</span>
          </div>
          <!-- 户名 -->
          <div class="username">
            <span> 户名： </span> <span> {{ selectedRows?.accountName }}</span>
          </div>
          <!-- 账户余额 -->
          <div class="blane">
            <span> 账户余额（元）： </span>
            <span>
              {{ '￥' + formattedAvailBalance(selectedRows?.availBalance) }}
            </span>
          </div>
        </div>

        <!-- 缴纳金额 -->
        <div class="Payoverbox">
          <div class="left-title">缴纳金额：</div>
          <a-input type="number" v-model.trim="Payovernum" class="Payover">
            <template #suffix>
              <div class="yaun">元</div>
            </template>
          </a-input>
        </div>

        <!--短信验证码-->

        <div class="codebox">
          <div class="left-title">短信验证码：</div>
          <a-input v-model.trim="Payovercode" class="code"></a-input>
          <a-button v-if="show" type="primary" @click="sendcode">
            发送验证码
          </a-button>
          <div v-else style="padding-left: 35px">{{ Countdown }}S</div>
        </div>
      </div>
      <!-- 保证金释放 -->
      <div v-else class="SecuritydepositRelease">
        <!-- 释放 -->
        <div class="userinfo">
          <!-- 账号 -->
          <div class="Account number">
            <span> 保证金账号： </span>
            <span>{{
                releaseData.accountNo
              }}</span>
          </div>
          <!-- 户名 -->
          <div class="username">
            <span> 保证金用名户名： </span>
            <span> {{ releaseData.accountName }}</span>
          </div>
          <!-- 金额 -->
          <div class="money" style="display: flex;">
            <span> 保证金金额（元）： </span>
            <span>
              <Balance :amount="releaseData.balance"></Balance>
            </span>

          </div>

          <!-- AI账户 -->
          <div class="aiaccount">
            <span>A1账户:</span>
            <span> {{ selectedRows?.accountName }}</span>
          </div>
        </div>

        <!-- 释放金额 -->
        <div class="Payoverbox">
          <div class="left-title">释放金额</div>
          <a-input type="number" v-model.trim="Releaseamount" class="Payover">
            <template #suffix>
              <div class="yaun">元</div>
            </template>
          </a-input>
        </div>

        <!--短信验证码-->

        <div class="codebox">
          <div class="left-title">短信验证码：</div>
          <a-input v-model.trim="Releasecode" class="code"></a-input>
          <a-button v-if="show" type="primary" @click="sendcode">
            发送验证码
          </a-button>
          <div v-else style="padding-left: 35px">{{ Countdown }}S</div>
        </div>
      </div>
      <!-- 缴纳 -->
      <template v-slot:footer>
        <div v-if="Securitydeposittype == 0" class="custom-footer">
          <a-button @click="Securitydepositvisible = false">取消</a-button>
          <a-button type="primary" @click="Confirmationofpayment">缴纳</a-button>
        </div>
        <!-- 释放 -->
        <div v-else class="custom-footer">
          <a-button @click="Securitydepositvisible = false">取消</a-button>
          <a-button type="primary" @click="Release">释放</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 缴纳成功弹层 -->
    <a-modal title="缴纳成功" :maskClosable="true" :centered="true" :visible="SecuritydepositSuccessfulvisible"
             @cancel="SecuritydepositSuccessfulvisible = false">
      <div class="SecuritydepositSuccessful">
        <h3>
          保证金缴纳成功，因检测到该A1账户未开立保证金账户，
          已为您生成保证金账户如下
        </h3>

        <div class="info">
          <div class="item"><span>保证金账户账号:</span> <span></span></div>
          <div class="item"><span>保证金账户户名:</span> <span></span></div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="SecuritydepositSuccessfulcustom-footer">
          <a-button type="primary" @click="SecuritydepositSuccessfulvisible = false">确认</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 选择贷款类型弹窗 -->
    <a-modal title="选择贷款类型" :maskClosable="true" @cancel="loanvisible = false" :centered="true"
             :visible="loanvisible"
             :footer="null">
      <div class="Openanaccount">
        <!--个人贷款  -->

        <div class="personage" @click="Personalloan">个人贷款</div>

        <!-- 企业贷款 -->
        <div class="firm" @click="Enterpriseloan">企业贷款</div>
      </div>
    </a-modal>
    <!-- 小额保证弹框  -->
    <PaymentAuthDialog v-if="visibleDailog" :selectedAccount="selectedRows" :type="'list'"
                       @OnPaymentChange="OnPaymentChange">
    </PaymentAuthDialog>
    <!-- 绑定银行卡组件 -->
    <BindBankCard :visible="visibleBindBankCard" @handleBind="handleBind"
                  :selectedAccount="selectedRows"></BindBankCard>
  </div>
  <!-- 账户管理 -->
</template>
<script>
// import QRCode from 'qrcode'
import {handleChannelNo} from '@/utils/commonMethods'
import WalletInfo from '@/view/components/WalletInfo.vue'
import Balance from '@/view/components/Balance.vue'
import PaymentAuthDialog from '@/view/components/PaymentAuthDialog.vue'
import BindBankCard from '../Components/BindBankCard.vue'
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";


export default {
  name: 'accountList',
  components: {
    DepartmentChoose,
    WalletInfo,
    DeptInfo,
    Balance,
    PaymentAuthDialog,
    BindBankCard
  },


  data() {
    return {
      // 存放保证金释放的数据
      releaseData: {},
      // 控制绑定银行卡弹框
      visibleBindBankCard: false,
      visibleDailog: false,
      openAccSubDailog: false,
      // 搜索表单数据
      loanvisible: false, // 贷款弹窗
      selectedRowKeys: [],
      selectedRows: {}, //
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      Countdown: 60,
      show: true,
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
              `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
              Number(index + 1)
              }`,
          align: 'center',
          width: 60,
          fixed: 'left'
        },
        {
          title: '客户类型',
          dataIndex: 'accountProperty',
          align: 'center',
          ellipsis: true,
          width: 60,
          fixed: 'left',
          customRender: (text) => text === "1" ? "个人" : "企业"
        },
        {
          title: '账户名称',
          dataIndex: 'accountName',
          align: 'center',
          fixed: 'left',
          width: 260,
        },
        {
          title: '活期',
          dataIndex: 'a0',
          align: 'center',
          sorter: true,
          width: 220,
          ellipsis: true,
          sortDirections: ['descend'],
          customRender: (item) => {
            if (!item) {
              return <div></div>
            }

            return <div>
              <WalletInfo showAccountNo={false} accountName={item.accountName} accountNo={item.accountNo}
                          accountClass={item.accountClass} balance={item.balance} details={[]}></WalletInfo>
            </div>
          },
        },
        {
          title: '冻结',
          dataIndex: 'a6',
          align: 'center',
          sorter: true,
          width: 240,
          ellipsis: true,
          sortDirections: ['descend'],
          customRender: (text, record,) => {

            if (!record.arrList) {
              return <div></div>;
            }

            const a6Accounts = record.arrList.filter(account => account.accountClass === '6');
            const a7Accounts = record.arrList.filter(account => account.accountClass === '8');

            return (
                <div>
                  {a6Accounts.length > 0 && (
                      <span>
                        <WalletInfo
                            showAccountNo={false}
                            accountName={a6Accounts[0].accountName}
                            accountNo={a6Accounts[0].accountNo}
                            accountClass={a6Accounts[0].accountClass}
                            balance={a6Accounts[0].balance}
                        />
                      </span>
                  )}
                  {a7Accounts.length > 0 && (
                      <span>
                      <a-popover title="分户信息" trigger="click">
                        <template slot="content">
                          <div style="max-height: 200px; width: 400px; overflow-y: auto;">
                            {a7Accounts.map((account, index) => (
                                <WalletInfo
                                    key={index}
                                    num={index + 1}
                                    showAccountNo={true}
                                    accountName={account.accountName}
                                    accountNo={account.accountNo}
                                    accountClass={account.accountClass}
                                    balance={account.balance}
                                />
                            ))}

                          </div>
                        </template>
                        <a-button type="link">更多</a-button>
                      </a-popover>
                      </span>
                  )}
                </div>
            );
          },
        },
        {
          title: '保证',
          dataIndex: 'a2',
          align: 'center',
          sorter: true,
          width: 220,
          ellipsis: true,
          sortDirections: ['descend'],
          customRender: (item) => {
            if (!item) {
              return <div></div>
            }
            return <div>
              <WalletInfo showAccountNo={false} accountName={item.accountName} accountNo={item.accountNo}
                          accountClass={item.accountClass} balance={item.balance}></WalletInfo>
            </div>
          },
        },
        {
          title: '贷款',
          dataIndex: 'a7',
          align: 'center',
          sorter: true,
          width: 220,
          ellipsis: true,
          sortDirections: ['descend'],
          customRender: (item) => {
            if (!item) {
              return <div></div>
            }
            return <div>
              <WalletInfo showAccountNo={false} accountName={item.accountName} accountNo={item.accountNo}
                          accountClass={item.accountClass} balance={item.balance}></WalletInfo>
            </div>
          },
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          align: 'center',
          width: 120,
          customRender: (text) => {
            let starizedText = text.replace(/^(.{3}).+(.{4}$)/, "$1****$2")
            return <a-tooltip>
              <template slot="title"> {{text}} </template>
              {starizedText} </a-tooltip>
          }
        },
        {

          title: '证件号',
          dataIndex: 'certnum',
          align: 'center',
          width: 150,
          customRender: (text) => {
            if (!text) return text
            let starizedText = text.replace(/^(.{4}).+(.{4}$)/, "$1****$2")
            return <a-tooltip>
              <template slot="title"> {{text}} </template>
              {starizedText} </a-tooltip>
          }
        },

        {
          title: '状态',
          dataIndex: 'accountStatus',
          align: 'center',
          width: 60,
          customRender: (text) => {
            if (Number(text == 0)) {
              return '正常'
            } else if (Number(text == 1)) {
              return '删除'
            } else if (Number(text == 2)) {
              return '挂失'
            } else if (Number(text == 3)) {
              return '锁定'
            } else if (Number(text == 4)) {
              return '销户'
            } else if (Number(text == 5)) {
              return '不动户'
            } else if (Number(text == 6)) {
              return '睡眠户'
            } else if (Number(text == 7)) {
              return '止付'
            } else if (Number(text == 8)) {
              return '证件过期'
            } else {
              return text != null ? text : '---'
            }
          },
        },
        {
          title: '开户日期',
          dataIndex: 'createTime',
          align: 'center',
          ellipsis: true,
          customRender: (text) => {
            return text?.substring(0,10) || ""
          },
        },
        {
          title: '渠道',
          dataIndex: 'channelNo',
          align: 'center',
          ellipsis: true,
          customRender: (text) => {
            return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
          },
        },
        {
          title: '机构',
          dataIndex: 'departmentId',
          align: 'center',
          ellipsis: true,
          customRender: (text) => {
            return <DeptInfo dept-id={text} />
          },
        }
      ],
      // 表格数据
      data: [],
      total: 0, // 总数
      form: {
        sortField: '',
        sortOrder: 'desc',
        pageNo: 1,
        pageSize: 10,
        //departmentId: undefined, //部门id
        departmentCode: '', //部门id
        custnum: '', // 客户编号
        custname: '', // 客户名称
        certnum: '', // 证件号
        accountNo: '', // 账号
        accountName: '', // 户名
        channelNo: undefined, // 渠道编号
        appNo: '', //应用编号
        mobile: '', // 手机号
        accountProperty: '',//客户类型
      },
      // 控制开户弹窗显示
      Openanaccountvisible: false,
      // 控制保证金弹窗
      Securitydepositvisible: false,
      // 缴纳金额
      Payovernum: '',
      Payovercode: '',
      // 释放金额，验证码
      Releaseamount: '',
      Releasecode: '',
      // 缴纳成功
      SecuritydepositSuccessfulvisible: false,
      // 保证金弹窗类型 0 缴纳 1 释放
      Securitydeposittype: '',

    }
  },
  computed: {
    // 处理余额显示格式
    formattedAvailBalance() {
      return function (amount) {
        let amountStr = Number(amount).toFixed(2);
        return amountStr.replace().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
    // formattedAvailBalance(amount) {
    //   let amountStr = Number(amount).toFixed(2);
    //   return amountStr.replace().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // },
  },
  created() {
    this.form.departmentCode = this.$session.getItem("companyCode");
    this.channelList = JSON.parse(sessionStorage.getItem("coreCompanyList"))
  },
  mounted() {
    // 调用获取数据
    if (sessionStorage.getItem('msgInfo')) {
      this.form = this.$store.state.searchMsgInfo
    }
    this.getcustaccountPage()
  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')
  },
  destroyed() {
    // console.log('destroyed');
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    changeSorter(pagination, filters, sorter) {
      this.form.sortOrder = 'desc'
      if (sorter?.order === 'descend') { // 指定字段倒序排序
        this.form.sortField = sorter?.field || 'a0'
      } else { // 默认排序
        this.form.sortField = ''
      }
      this.form.sortOrder = sorter?.order === 'ascend' ? 'asc' : 'desc'
      this.getcustaccountPage()
    },
    // 获取table列表
    async getcustaccountPage() {
      let data = JSON.parse(JSON.stringify(this.form))
      const res = await this.api.accountListPageApi(data)
      // console.log(res.data.records, 'data');
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      res.data.records.forEach((item) => {
        this.modifyTree(item)
      })
      this.data = res.data.records
      // console.log(res.data.records);
    },
    // 树形表格数据
    modifyTree(item) {
      let arr = []

      // 先将父节点数据放到数组
      let objData = {
        accountName: item.accountName,
        accountNo: item.accountNo,
        accountClass: item.accountClass,
        balance: item.balance
      }
      arr.push(objData)
      if (item.childAccounts && item.childAccounts.length > 0) {

        item.childAccounts.forEach(obj => {
          let childrenObj = {
            accountName: obj.accountName,
            accountNo: obj.accountNo,
            accountClass: obj.accountClass,
            balance: obj.balance
          }
          // 再将子节点数据放到数组里
          arr.push(childrenObj)
        })
      }
      item.arrList = arr

      item.a0 = arr.filter(acct => acct.accountClass === '0')[0]
      item.a2 = arr.filter(acct => acct.accountClass === '2')[0]
      item.a6 = arr.filter(acct => acct.accountClass === '6')[0]
      item.a7 = arr.filter(acct => acct.accountClass === '7')[0]
      item.a8 = arr.filter(acct => acct.accountClass === '8')[0]

      delete item.childAccounts

    },
    // 点击重置表单
    reset() {

      this.form = {
        // departmentId: undefined, //部门id
        departmentCode: undefined, //部门id
        custnum: '', // 客户编号
        custname: '', // 客户名称

        accountNo: '', // 账号
        accountName: '', // 户名
        channelNo: '', // 渠道编号

        appNo: '', //应用编号
        mobile: '', // 手机号
        pageNo: 1,
        pageSize: 10,
      }
      this.getcustaccountPage()
    },

    // 发送验证码
    async sendcode() {
      var data = {
        appNo: Number(this.selectedRows.appNo),
        channelNo: Number(this.selectedRows.channelNo),
        mobile: Number(this.selectedRows.mobile),
        tradeType: 9,
        modelNo: '',
      }
      // 缴纳  102010  释放  102011
      if (this.Securitydeposittype == 0) {
        data.modelNo = '102010'
      } else {
        data.modelNo = '102011'
      }

      const res = await this.api.vefifycodesend(data)

      if (res.code == 200) {
        this.$message.success('验证码发送成功')
        this.show = false
        // 60秒倒计时

        var timer = setInterval(() => {
          this.Countdown--
          if (this.Countdown <= 0) {
            this.show = true
            this.Countdown = 60
            clearInterval(timer)
          }
        }, 1000)
      } else {
        this.$message.error(res.msg)
      }
    },

    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
          this.getcustaccountPage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getcustaccountPage()
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

    // 个人贷款
    Personalloan() {
      this.$router.push('/Financingmanagement/Personalloan')
      // this.$store.commit('saveLoanpersonalinformation', this.selectedRows)
    },
    // 企业贷款
    Enterpriseloan() {
      this.$router.push('/Financingmanagement/Enterpriseloan')
    },
    // 开户
    Openanaccount() {
      this.Openanaccountvisible = true
    },
    // 修改
    edit() {
    },
    // 余额同步
    async Balancesynchronization() {
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.selectedRows.channelNo,
        account_no: this.selectedRows.accountNo,
        relation_acct: this.selectedRows.accountRelation,
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success('余额已同步')
      this.selectedRows.availBalance = res.data.detail_list.find(i => i.account_no === this.selectedRows.accountNo)?.avail_balance
      await this.getcustaccountPage()
    },
    // A2账户开户
    async openAccSubDailogClick() {

      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }
      this.openAccSubDailog = true;

    },
    async openAccSubCancel() {
      this.openAccSubDailog = false;
    },
    async openAccSub() {

      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }
      this.openAccSubDailog = false;
      const res = await this.api.openAccSub({
        channel_no: this.selectedRows.channelNo,
        account_no: this.selectedRows.accountNo,
        account_name: this.selectedRows.accountName,
        account_class: 8,
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }

      this.$message.success('分户开户成功')

      await this.getcustaccountPage()
    },
    // 保证金缴纳
    async Marginpayment() {
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }
      // 账户类型是0 活期  才可以缴纳
      if (Number(this.selectedRows.accountClass) === 0) {

        // 余额同步
        await this.Balancesynchronization()
        this.Securitydeposittype = 0
        // 打开弹框
        this.Securitydepositvisible = true
      } else {
        this.$message.warning('请使用活期账号缴纳')
      }
    },
    // 保证金释放
    async Marginrelease() {
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }
      // console.log(this.selectedRows);
      let val = this.selectedRows.arrList?.find(item => Number(item.accountClass) === 2)
      // console.log(val, 'val');
      if (val) {
        this.releaseData = val
        // 保证金账号才能释放
        await this.Balancesynchronization()
        this.Securitydeposittype = 1
        this.Securitydepositvisible = true
      } else {
        this.$message.warning('此账号不具备保证金释放的条件')
      }
      // if (Number(this.selectedRows.accountClass) === 2) {// 保证金账号才能释放
      //   await this.Balancesynchronization()
      //   this.Securitydeposittype = 1
      //   this.Securitydepositvisible = true
      // } else {
      //   this.$message.warning('请选中一个保证金账号进行释放')
      // }
    },
    // 搜索查询
    search() {
      this.form.pageNo = 1
      this.getcustaccountPage(),
          this.selectedRowKeys = [],
          this.selectedRows = {}
    },
    // 贷款申请
    Loanapplication() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      // 个人
      if (Number(this.selectedRows.accountProperty) == 1) {
        this.$router.push({
          name: "Personalloan",
          params: {
            custnum: this.selectedRows.custnum,
            type: 'add'
          }
        })
      }
      // 企业
      if (Number(this.selectedRows.accountProperty) == 2) {
        this.$router.push({
          name: 'Enterpriseloan',
          params: {
            custnum: this.selectedRows.custnum,
            type: 'add',
          },
        })
      }
      this.$store.commit('saveWalletinformation', this.selectedRows)
    },
    // 小额打款认证
    paymentAuth() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      this.visibleDailog = true
    },
    // 绑定银行卡
    bindBankCard() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      this.visibleBindBankCard = true
    },
    handleBind(obj) {
      this.visibleBindBankCard = obj.bool
      if (obj.type === 'success') {
        // 绑定银行卡成功，重新刷新页面
        this.getcustaccountPage()
      }
    },
    // 修改账户
    editAccount() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      // 存储条件到vuex
      let storeData = {
        id: this.selectedRows.id,
        channel_no: this.selectedRows.channelNo,
        app_no: this.selectedRows.appNo,
        account_no: this.selectedRows.accountNo,
        accountName: this.selectedRows.accountName,
        custnum: this.selectedRows.custnum,
      }
      this.$store.commit('saveAccountEditData', storeData)
      if (this.selectedRows.accountProperty === '1') {
        // 个人
        this.$router.push('/wallet/IndividualAccountEdit')
      } else {
        // 企业
        this.$router.push('/wallet/CorporateAccountEdit')
      }
    },

    // 查看开户信息
    openAccView() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      if (this.selectedRows.accountProperty === '1') {
        // 个人
        this.$router.push({
          path: "/wallet/openAccPersonalInfo",
          query: {
            id: this.selectedRows.id,
          },
        });
      } else {
        // 企业
        this.$router.push({
          path: "/wallet/openaccEnterpriseInfo",
          query: {
            id: this.selectedRows.id,
          },
        });      }
    },
    OnPaymentChange(bool) {
      this.visibleDailog = bool
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      if (selectedRows?.length > 0) {
        //获取选中的数据的key
        var selectNumber = this.selectedRowKeys[1]
        //清空选中的key
        this.selectedRowKeys = []
        //选中的数据的key重新赋值给selectedRowKeys
        this.selectedRowKeys.push(selectNumber)
      }
      //获取选中的数据详细信息
      this.selectedRows = selectedRows[0]

      // 每次训中都要清空上次输入得数据
      this.Releasecode = ''
      this.Releaseamount = ''
      this.Payovernum = ''
      this.Payovercode = ''
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString();
            if (id == this.selectedRowKeys[0]) {
              this.selectedRowKeys = []
              this.selectedRows = {}
            } else {
              this.selectedRowKeys = []
              this.selectedRowKeys.push(id)
              this.selectedRows = record
            }
          }
        }
      }
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getcustaccountPage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    pagechange(val) {
      this.form.pageNo = val
      this.getcustaccountPage()
    },
    // 保证金确认缴纳函数
    async Confirmationofpayment() {
      console.log(111)
      if (this.Payovernum == '' || this.Payovercode == '') {
        this.$message.warning('请先输入缴纳金额和验证码')
        return
      }
      // 校验验证码,金额
      if (this.Payovernum !== '' && this.Payovercode !== '') {
        // 验证码校验

        let data = {
          channel_no: this.selectedRows.channelNo,
          app_no: this.selectedRows.appNo,
          account_no: this.selectedRows.accountNo,
          account_name: this.selectedRows.accountName,
          trade_amount: this.Payovernum,
          verify_code: this.Payovercode,
          remark: '',
        }
        const res = await this.api.bailbailInsureApi(data)

        if (res.code !== 200) {
          this.$message.error(res.info)
          return
        }
        this.show = true
        this.Countdown = 60
        this.Payovernum = ''
        this.Payovercode = ''
        this.Securitydepositvisible = false
        // this.SecuritydepositSuccessfulvisible = true
        this.$message.success('保证金缴纳成功')
        ;(this.selectedRowKeys = []),
            (this.selectedRows = []), //
            this.getcustaccountPage()
      }
    },
    // 释放
    async Release() {
      if (this.Releaseamount == '' || this.Releasecode == '') {
        this.$message.warning('请先输入释放金额和验证码')
        return
      }

      // 校验验证码,金额
      if (this.Releaseamount !== '' && this.Releasecode !== '') {
        let data = {
          channel_no: this.selectedRows.channelNo,
          app_no: this.selectedRows.appNo,
          account_no: this.selectedRows.accountNo,
          account_name: this.selectedRows.accountName,
          trade_amount: this.Releaseamount,
          verify_code: this.Releasecode,
          remark: '',
        }
        const res = await this.api.bailRepayApi(data)

        if (res.code !== 200) {
          this.$message.error(res.info)
          return
        }
        this.show = true
        this.Countdown = 60
        this.$message.success('保证金释放成功')
        this.Securitydepositvisible = false
        this.Releasecode = ''
        this.Releaseamount = ''
        this.selectedRowKeys = []
        this.selectedRows = []
        this.getcustaccountPage()
      }
    },
    // 点击查看
    view() {
      if (this.selectedRows.length == 0 || !this.selectedRows) {
        this.$message.warning('请先选择账户')
        return
      }
      // 进入详情页存储筛选条件 
      this.$store.commit('saveSearchMsgInfo', this.form)
      this.$store.commit('saveAccountmanagementinfo', this.selectedRows)
      this.$router.push('/wallet/accountview')
    },
    // 个人开户
    Individualaccountopening() {
      this.Openanaccountvisible = false
      this.$router.push('/wallet/Individualaccountopening')
    },
    // 企业开户
    CorporateAccountOpening() {
      this.Openanaccountvisible = false
      this.$router.push('/wallet/CorporateAccountOpening')
    },
    //内部户开户
    innerAccountOpening() {
      this.Openanaccountvisible = false
      this.$router.push('/wallet/InnerAccountOpen')
    }
  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}

.Walletbox {
  width: 100%;

  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 280px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .lefttitle {
        margin-right: 8px;
      }

      input {
        width: 200px;
      }
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }
  }

  .Controls {
    padding-top: 10px;
    margin-bottom: 20px;

    button {
      min-width: 64px;
    }

    .edit {
      background-color: #d7d7d7;
      color: #fff;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  .paginationBox {
    display: flex;
    justify-content: flex-end;

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  // 开户弹框
}

.Openanaccount {
  padding: 30px;

  .personage {
    width: 100%;
    height: 40px !important;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .firm {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
}

.ant-modal-footer {
  border: none;
  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;
}

// 保证金
.Securitydeposit,
.SecuritydepositRelease {
  padding-bottom: 20px;

  .userinfo {
    padding: 20px;
    background-color: #f5f8ff;
    min-height: 130px;

    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .blane {
      margin-bottom: 0px;
    }

    margin-bottom: 30px;
  }

  .Payoverbox {
    display: flex;
    padding-right: 0px;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    .Payover {
      .ant-input {
        position: relative;
        width: 220px;
        padding-right: 0px !important;
      }

      .ant-input-suffix {
        position: absolute;
        width: 40px;
        right: 0px;
        top: 0;
        margin-top: 16px;
        margin-left: 20px;
        height: 32px;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .codebox {
    display: flex;
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    .code {
      width: 110px;
      margin-right: 10px;
    }
  }
}

.SecuritydepositSuccessful {
  h3 {
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .info {
    padding: 20px;
    width: 100%;
    min-height: 90px;
    background-color: #f5f8ff;

    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
