import { render, staticRenderFns } from "./Accountview.vue?vue&type=template&id=dbede40e&scoped=true"
import script from "./Accountview.vue?vue&type=script&lang=js"
export * from "./Accountview.vue?vue&type=script&lang=js"
import style0 from "./Accountview.vue?vue&type=style&index=0&id=dbede40e&prod&lang=css"
import style1 from "./Accountview.vue?vue&type=style&index=1&id=dbede40e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbede40e",
  null
  
)

export default component.exports