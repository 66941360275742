<template>
  <!-- 支付协议管理列表页 -->
  <div>
    <a-card title="支付协议管理" style="margin: 20px">
      <!-- 头部筛选区域 -->
      <div class="selected-box">
        <!-- 机构ID -->
        <div class="selected-box-item">
          <span class="left-item">机构</span>
          <DepartmentChoose :department-id.sync="selectedData.departmentId" @change="handleQuery"></DepartmentChoose>
        </div>
        <!-- 电商编号 -->
        <div class="selected-box-item">
          <span class="left-item">电商编号</span>
          <a-input v-model.trim="selectedData.mchNo" placeholder="请输入电商编号"></a-input>
        </div>
        <!-- 电商名称 -->
        <div class="selected-box-item">
          <span class="left-item">电商名称</span>
          <a-input v-model.trim="selectedData.mchName" placeholder="请输入电商名称"></a-input>
        </div>
        <!-- 收款账号 -->
        <div class="selected-box-item">
          <span class="left-item">收款账号</span>
          <a-input v-model.trim="selectedData.oppAccountNo" placeholder="请输入收款账号"></a-input>
        </div>
        <!-- 收款户名 -->
        <div class="selected-box-item">
          <span class="left-item">收款户名</span>
          <a-input v-model.trim="selectedData.oppAccountName" placeholder="请输入收款户名"></a-input>
        </div>
        <!-- 付款账号 -->
        <div class="selected-box-item">
          <span class="left-item">付款账号</span>
          <a-input v-model.trim="selectedData.accountNo" placeholder="请输入付款账号"></a-input>
        </div>
        <!-- 付款户名 -->
        <div class="selected-box-item">
          <span class="left-item">付款户名</span>
          <a-input v-model.trim="selectedData.accountName" placeholder="请输入付款户名"></a-input>
        </div>
        <!-- 类型 -->
<!--        <div class="selected-box-item">-->
<!--          <span class="left-item">订单类型</span>-->
<!--          <a-select v-model="selectedData.orderType">-->
<!--            <a-select-option value="0">小额打款</a-select-option>-->
<!--            <a-select-option value="1">MCN</a-select-option>-->
<!--            <a-select-option value="2">主播</a-select-option>-->
<!--            <a-select-option value="3">厂家</a-select-option>-->
<!--            <a-select-option value="4">其他</a-select-option>-->
<!--          </a-select>-->
<!--        </div>-->
        <!-- 同步状态 -->
<!--        <div class="selected-box-item">-->
<!--          <span class="left-item">同步状态</span>-->
<!--          <a-select v-model="selectedData.synch">-->
<!--            <a-select-option value=""> 全部 </a-select-option>-->
<!--            <a-select-option value="1"> 是</a-select-option>-->
<!--            <a-select-option value="0"> 否 </a-select-option>-->
<!--          </a-select>-->
<!--        </div>-->
        <div class="selected-box-item" style="display: block">
          <a-button type="primary" @click="handleQuery" icon="search">查询</a-button>
          <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="table-box">
        <div class="table-box-handle">
          <a-button type="primary" v-if="$authority('paymentAgreementAddition')">
            <router-link :to="{ name: 'AddPayment', query: { type: 'add' } }" target="_self" rel="opener">
              <a-icon type="plus"/>新增
            </router-link>
          </a-button>
          <a-button type="primary" @click="editBtn" icon="edit"
                    :disabled="selectCustData.length!==1 || (selectCustData && selectCustData[0].checkStatus == 1)"
                    v-if="$authority('paymentAgreementModification')">修改</a-button>
          <a-button type="primary" @click="viewBtn" icon="eye"
                    :disabled="selectCustData.length!==1"
                    v-if="$authority('paymentAgreementView')">查看</a-button>
          <a-button type="danger" @click="delBtn" icon="delete"
                    :disabled="selectCustData.length!==1"
                    v-if="$authority('paymentAgreementDeletion')">删除</a-button>
          <a-button type="primary" @click="pushToBank" icon="audit"
                    :disabled="selectCustData.length!==1 || (selectCustData && selectCustData[0].checkStatus == 1)"
                    v-if="$authority('paymentAgreementPush')">审批</a-button>

        </div>
        <div class="table-main">
          <a-table size="small" :scroll="{ x: 2100, }" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :customRow="clickrow" :columns="columns" :data-source="data" :pagination="false"
            :rowKey="(record, index) => `${record.id}`">
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(pageNo) == 1" @click="Homepage">
          首页
        </a-button> <a-pagination v-model.trim="pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage" :disabled="pageNo == Math.ceil(Number(total) / Number(pageSize))"
          class="Trailingpage">
          尾页
        </a-button>
      </div>
      <!-- 删除按钮弹框 -->
      <div class="del-dialog">
        <a-modal title="提示" :body-style="{ textAlign: 'center' }" :visible="isShowDelDialog" :centered="true"
          @ok="handleOk" @cancel="isShowDelDialog = false">
          <p>是否确认删除?</p>
        </a-modal>
      </div>
      <!-- 审批按钮弹框 -->
      <div class="del-dialog">
        <a-modal title="审批确认" :body-style="{ textAlign: 'center' }" :visible="auditConfirmVisible" :centered="true"
                 @ok="pushMethod" @cancel="auditConfirmVisible = false" okText="推送" cancelText="取消">
          <p>审批通过后将推送支付协议到银行</p>
        </a-modal>
      </div>
    </a-card>
  </div>
</template>

<script>
import {message} from 'ant-design-vue'
import {handleChannelNo} from '@/utils/commonMethods'
import WalletInfo from '@/view/components/WalletInfo.vue'
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

const data = []

export default {
  name: 'PaymentProtocolManage',
  props: {},
  components: {DepartmentChoose, WalletInfo },
  data() {
    return {
      // 表格数据
      data,
      columns: [
        {
          title: 'No',
          customRender: (text, record, index) =>
            `${Number(this.pageNo - 1) * Number(this.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
          key: 'sort',
          width: 55
        },
        {
          title: '电商名称',
          dataIndex: 'mchName',
          key: 'mchName',
          align: 'center',
          ellipsis: true,
          width: 260,
        },
        {
          title: '电商编号',
          dataIndex: 'mchNo',
          key: 'mchNo',
          align: 'center',
          width: 160,
          ellipsis: true
        },
        // {
        //   title: '订单类型',
        //   dataIndex: 'orderType',
        //   key: 'orderType',
        //   align: 'center',
        //   ellipsis: true,
        //   customRender: (text) => { return { "0": "小额打款", "1": "MCN", "2": "主播", "3": "厂家", "4": "其他" }[text] }
        // },
        {
          title: '付款账号',
          dataIndex: 'accountNo',
          align: 'center',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.accountName}
                               accountNo={text}
                               accountClass={record.accountClass}
            ></WalletInfo>
          },
          width: 220,
        },
        {
          title: '收款户名',
          dataIndex: 'oppAccountName',
          key: 'oppAccountName',
          align: 'center',
          width: 260,
          ellipsis: true
        },
        {
          title: '收款账号',
          dataIndex: 'oppAccountNo',
          key: 'oppAccountNo',
          align: 'center',
          width: 260,
          ellipsis: true,
          customRender: (text, record) => {
            return <WalletInfo accountName={record.oppAccountName}
              accountNo={record.oppAccountNo}
              bankName={record.oppBankName} bankNo={record.oppBankNo}
              isWallet={record.oppAccountType == '2' ? false : true}
            ></WalletInfo>
          }
        },
        {
          title: 'CG店铺',
          dataIndex: 'cgShopName',
          key: 'cgShopName',
          align: 'center',
          width: 200,
          ellipsis: true,
        },
        {
          title: "审批状态",
          dataIndex: "checkStatus",
          align: "center",
          width: 80,
          customRender: (text) => {
            if(text === '0') {
              return <a-tag color="orange">待审批</a-tag>
            }
            if(text === '1') {
              return <a-tag color="green">通过</a-tag>
            }
            if (text === '2') {
              return <a-tag color="red">拒绝</a-tag>
            }
            if(text === '3') {
              return <a-tag color="grey">异常</a-tag>
            }
          },
        },
        {
          title: '创建人',
          dataIndex: 'creater',
          key: 'creater',
          align: 'center',
          width: 160,
          ellipsis: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          align: 'center',
          ellipsis: true
        },
        {
          title: '渠道',
          dataIndex: 'channelNo',
          key: 'channelNo',
          align: 'center',
          customRender: (text) => {
            return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
          },
        },
        // {
        //   title: '应用编号',
        //   dataIndex: 'appNo',
        //   key: 'appNo',
        //   align: 'center',
        //   customRender: (text) => {
        //     return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '2')
        //   },
        // },

      ],
      // 筛选参数
      selectedData: {
        // 机构ID
        departmentId: undefined,
        // 电商编号
        mchNo: '',
        // 电商名称
        mchName: '',
        // 收款账号
        oppAccountNo: '',
        // 收款户名
        oppAccountName: '',
        // 渠道编号
        channelNo: undefined,
        // 应用编号
        appNo: '',
        // 付款账号
        accountNo: '',
        //付款户名
        accountName: '',
        orderType: '',
        synch: ''
      },
      // 当前页
      pageNo: 1,
      // 一页显示多少条
      pageSize: 10,
      // 总数
      total: 0,
      //分页大小
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      isShowDelDialog: false,
      selectedRowKeys: [],
      selectCustData: [],
      // 审批确认弹窗
      auditConfirmVisible: false
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {

    if (sessionStorage.getItem('msgInfo')) {
      this.selectedData = this.$store.state.searchMsgInfo
    }
    // 获取列表
    this.getPayProtocolList()
  },
  activated() {
    if (this.$route.query.fromAddPage) {
      this.pageNo = 1
      this.selectedData = {
        // 机构ID
        departmentId: undefined,
        // 电商编号
        mchNo: '',
        // 电商名称
        mchName: '',
        // 收款账号
        oppAccountNo: '',
        // 收款户名
        oppAccountName: '',
        // 渠道编号
        channelNo: undefined,
        // 应用编号
        appNo: '',
        // 付款账号
        accountNo: '',
        //付款户名
        accountName: '',
        orderType: '',
        synch: ''
      }
      this.getPayProtocolList()
    } else if (this.$route.query.fromEditPage) {
      this.getPayProtocolList()
    } else if(this.$route.query.mchNo){
      this.selectedData.mchNo = this.$route.query.mchNo
      this.getPayProtocolList()
    }
  },
  // deactivated() {
  //   sessionStorage.removeItem('msgInfo')
  // },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

    // 获取列表函数
    async getPayProtocolList() {
      let data = {}
      data.pageNo = this.pageNo
      data.pageSize = this.pageSize
      // obj是个对象
      data.obj = JSON.parse(JSON.stringify(this.selectedData))
      let res = await this.api.getPayProtocolListApi(data)
      if (res.code == 200) {
        this.data = res.data.records
        this.pageNo = res.data.current
        this.pageSize = res.data.size
        this.total = res.data.total
      } else {
        this.$message.error(res.info)
      }
    },
    // 查询
    handleQuery() {
      this.pageNo = 1
      this.selectedRowKeys = []
      this.selectCustData = []
      this.getPayProtocolList()
    },
    // 重置
    handleReset() {
      this.selectedRowKeys = []
      this.selectCustData = []
      this.pageNo = 1
      this.pageSize = 10
      this.selectedData = {
        // 机构ID
        departmentId: '',
        // 电商编号
        mchNo: '',
        // 电商名称
        mchName: '',
        // 收款账号
        oppAccountNo: '',
        // 收款户名
        oppAccountName: '',
        // 渠道编号
        channelNo: '',
        // 应用编号
        appNo: '',
        // 付款账号
        accountNo: '',
        //付款户名
        accountName: '',
      }
      this.getPayProtocolList()
    },
    // 改变pagesize的事件函数
    onShowSizeChange(current, pageSize) {
      // console.log(current);
      // console.log(pageSize);
      this.pageNo = current
      this.pageSize = pageSize
      this.getPayProtocolList()
    },
    // 改变当前页的事件
    handleChangePage(page) {
      this.pageNo = page
      this.getPayProtocolList()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.pageNo = 1,
        this.getPayProtocolList()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.pageNo = Math.ceil(Number(this.total) / Number(this.pageSize))
      this.getPayProtocolList()
    },
    pagechange(val) {
      this.pageNo = val
      this.getPayProtocolList()
    },
    // 选中行的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys.splice(-1, 1)
      this.selectedRows = selectedRows.splice(-1, 1)
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            if(this.selectedRowKeys[0] === record.id.toString()){
              this.selectedRowKeys = []
              this.selectCustData = []
            }else{
              this.selectedRowKeys = [record.id.toString()]
              this.selectCustData = [record]
            }
          }
        }
      }
    },
    // 编辑跳转
    editBtn() {
      if (this.selectCustData.length !== 1) {
        return message.warning('请选择一条记录')
      } else {
        // 存储筛选条件
        this.$store.commit('saveSearchMsgInfo', this.selectedData)
        let data = this.selectCustData[0]
        this.$router.push({
          path: '/orders/PaymentProtocolManage/AddPayment',
          query: {
            id: data.id,
            type: 'edit',
          },
        })
      }
    },
    // 查看跳转
    viewBtn() {
      // console.log(this.selectCustData, 'this.selectCustData');
      if (this.selectCustData.length == 0) {
        return message.warning('只能选择一个客户!')
      } else {
        // 存储筛选条件
        this.$store.commit('saveSearchMsgInfo', this.selectedData)
        let data = this.selectCustData[0]
        this.$router.push({
          path: '/orders/PaymentProtocolManage/AddPayment',
          query: {
            id: data.id,
            type: 'view',
          },
        })

      }
    },
    // 删除
    delBtn() {
      if (this.selectCustData.length == 0) {
        return message.warning('只能选择一个客户!')
      } else {
        this.isShowDelDialog = true
      }
    },
    // 推送银行
    pushToBank() {
      if (this.selectCustData.length === 0) {
        return message.warning('请选择一条记录')
      } else {
        this.auditConfirmVisible = true
        // this.pushMethod()
      }
    },
    // 推送到银行接口
    async pushMethod() {
      let data =
      {
        id: this.selectCustData[0].id
      }
      let res = await this.api.paySyncToBankApi(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
      }
      this.$message.success(res.info)
      this.auditConfirmVisible = false
    },
    // 弹框确认删除事件
    async handleOk() {
      // console.log(this.selectCustData, 'ok')
      let idArr = []
      idArr.push(this.selectCustData[0].id)
      let res = await this.api.delProtocolApi(idArr)
      if (res.code == 200) {
        this.$message.success(res.info)
        this.selectedRowKeys = []
      } else {
        return this.message.error(res.info)
      }

      this.getPayProtocolList()
      this.isShowDelDialog = false
    },
  },
}
</script>

<style lang="less" scoped>
.selected-box {
  display: flex;
  flex-wrap: wrap;

  /deep/ .selected-box-item {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    width: 280px;
    margin-bottom: 20px;
    align-items: center;

    .left-item {
      margin-right: 15px;
    }

    .ant-input {
      width: 200px;
    }

    .ant-select-selection--single {
      width: 200px;
    }
  }
}

.table-box {
  .table-box-handle {
    margin-bottom: 20px;
  }
}

.paginationBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
</style>
