<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>店铺管理</span> <a-icon type="right" /> <span>{{ title }}</span>
          </div>
          <div class="right">
            <!-- 确认 -->
            <a-button type="primary" @click="confirm" v-if="$route.query.type !== 'view'"> 确认 </a-button>
            <!-- 取消 -->
            <a-button @click="confirmshow = true; type = 'cancel'"> 取消 </a-button>
          </div>
        </div>
      </template>
      <!-- 电商新增页面 -->
      <div class="Electronicbusiness">
        <!-- 基础信息 -->
        <div class="formbox">
          <div class="toptitle">基础信息*</div>
          <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">

            <!-- 销售平台 -->
            <a-form-model-item label="销售平台" class="spName" ref="spNo" prop="spNo">
              <a-radio-group v-model.trim="form.spNo" @change="spNochange" :disabled="$route.query.type == 'view'"
                placeholder="请选择销售平台">
                <a-radio-button v-for="item in spNamelist" :spName="item.label" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>

            <!-- 店铺类型 -->
            <!-- <a-form-model-item label="店铺类型" class="shopType" ref="shopType" prop="shopType">
              <a-radio-group v-model.trim="form.shopType" :disabled="$route.query.type == 'view'">
                <a-radio-button value="0"> 个人</a-radio-button>
                <a-radio-button value="1"> 企业</a-radio-button>
              </a-radio-group>
            </a-form-model-item> -->
            <!-- 是否自动提现 -->
            <a-form-model-item label="是否自动提现" class="autoWithdrawal" ref="autoWithdrawal" prop="autoWithdrawal"
              >
              <a-radio-group v-model.trim="form.autoWithdrawal" :disabled="$route.query.type == 'view'">
                <a-radio-button value="0"> 是</a-radio-button>
                <a-radio-button value="1"> 否</a-radio-button>
              </a-radio-group>
            </a-form-model-item>
            <!-- 电商 -->
            <a-form-model-item label="融资商户" class="mchName" ref="mchName" prop="mchName">
              <MchChooseDialog @chooseDone="onMchChosen" mchType="1" v-model="form.mchName"
                :department-code="form.departmentCode" style="display:inline-block"></MchChooseDialog>
            </a-form-model-item>
            <a-form-model-item label="商户编号" >
              {{form.mchNo}}

            </a-form-model-item>
            <!-- 店铺名称 -->
            <a-form-model-item label="店铺名称" class="shopName" ref="shopName" prop="shopName">
              <a-input v-model.trim="form.shopName" :disabled="$route.query.type == 'view'" placeholder="请输入店铺名称" />
            </a-form-model-item>
            <!--  店铺ID-->
            <a-form-model-item label="店铺ID" class="shopNo" ref="shopNo" prop="shopNo">
              <a-input v-model.trim="form.shopNo" :disabled="$route.query.type == 'view'" placeholder="请输入店铺ID" />
              <div> 请从快手/抖音后台查看，保证完全一致</div>
            </a-form-model-item>
            <!-- 管理员姓名 -->
            <a-form-model-item label="管理员姓名" class="managerName" ref="managerName" prop="managerName">
              <a-input v-model.trim="form.managerName" :disabled="$route.query.type == 'view'" placeholder="请输入管理员姓名" />
            </a-form-model-item>
            <!-- 管理员手机号 -->
            <a-form-model-item label="管理员手机号" class="managerMobile" ref="managerMobile" prop="managerMobile">
              <a-input type="number" :disabled="$route.query.type == 'view'" v-model.trim="form.managerMobile"
                placeholder="请输入管理员手机号" />
            </a-form-model-item>

            <a-form-model-item ref="name" label="回款账户" prop="refundAccountNo">
              <WalletChooseDialog v-model="form.refundAccountNo"  :account-class="'8'"
                                  @chooseDone="onRefundWalletChosen">
              </WalletChooseDialog>
            </a-form-model-item>

            <a-form-model-item ref="name" label="归集账户" prop="collAccountNo">
              <WalletChooseDialog v-model="form.collAccountNo" :open-account-class="true"
                                  @chooseDone="onCollWalletChosen">
              </WalletChooseDialog>
            </a-form-model-item>


          </a-form-model>
        </div>
        <div class="formbox Anchor">
          <div class="toptitle">主播</div>
          <a-button type="primary" v-if="$route.query.type !== 'view'" @click="addAnchor"> 新增 </a-button>
        </div>

        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" :rowKey="(record, index) => `${record.id}`" paginati :columns="columns"
              :data-source="mchBroadcastInfoslist" :pagination="false">
              <template slot="Controls" slot-scope="text, record,index">

                <div class="Controlsbox">
                  <a-button :disabled="$route.query.type == 'view'" @click="Editing(record, index)">编辑</a-button>
                  <a-button :disabled="$route.query.type == 'view'" @click="Deleterow(record, index)">删除</a-button>
                </div>
              </template>
            </a-table>
          </div>
          <!-- 分页部分 -->
          <!-- <div class="paginationBox">
            <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
              首页
            </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
              show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
              :item-render="itemRender">

            </a-pagination>
            <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
              class="Trailingpage">
              尾页
            </a-button>
          </div> -->
        </div>
        <div class="formbox Anchor">
          <div class="toptitle"> 商户店铺子账号表</div>
          <a-button type="primary" v-if="$route.query.type !== 'view'" @click="addsubaccount"> 新增 </a-button>
        </div>

        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" :rowKey="(record, index) => `${record.id}`" paginati :columns="rows"
              :data-source="mchShopManagerslist" :pagination="false">
              <template slot="Controls" slot-scope="text, record,index">

                <div class="Controlsbox">
                  <a-button :disabled="$route.query.type == 'view'" @click="Editshoping(record, index)">编辑</a-button>
                  <a-button :disabled="$route.query.type == 'view'" @click="Deleteshoprow(record, index)">删除</a-button>
                </div>
              </template>
            </a-table>
          </div>
          <!-- 分页部分 -->
          <!-- <div class="paginationBox">
            <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
              首页
            </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
              show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
              :item-render="itemRender">

            </a-pagination>
            <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
              class="Trailingpage">
              尾页
            </a-button>
          </div> -->
        </div>

      </div>
    </a-card>
    <!--  新增主播-->
    <a-modal title="新增主播" class="confirmloading" :maskClosable="true" @cancel="addAnchorshow = false" :centered="true"
      :visible="addAnchorshow">
      <div class="addform">
        <a-form-model ref="addruleForm" :model="addform" :rules="addrules" :label-col="addlabelCol"
          :wrapper-col="addwrapperCol">

          <!-- 主播/达人类型 -->
          <a-form-model-item label="主播/达人类型" class="custtypecd" ref="custtypecd" prop="custtypecd">

            <a-radio-group v-model.trim="addform.custtypecd">
              <a-radio-button value="1">
                个人
              </a-radio-button>
              <a-radio-button value="2">
                企业
              </a-radio-button>
            </a-radio-group>

          </a-form-model-item>
          <!-- 主播/达人昵称 -->
          <a-form-model-item ref="broadcastName" label="主播/达人昵称" prop="broadcastName">
            <a-input v-model="addform.broadcastName" placeholder="请填写主播/达人昵称 " />
          </a-form-model-item>
          <!-- 主播/达人ID -->
          <a-form-model-item ref="broadcastId" label="主播/达人ID" prop="broadcastId">
            <a-input v-model="addform.broadcastId" placeholder="请填写主播/达人ID" />
          </a-form-model-item>
          <!--          &lt;!&ndash; 账户类型 &ndash;&gt;-->
          <!--          <a-form-model-item label="账户类型" class="accountType" ref="accountType" prop="accountType">-->

          <!--            <a-radio-group v-model.trim="addform.accountType">-->

          <!--              <a-radio value="2">-->
          <!--                银行账户-->
          <!--              </a-radio>-->
          <!--              <a-radio value="1">-->
          <!--                钱包-->
          <!--              </a-radio>-->
          <!--            </a-radio-group>-->

          <!--          </a-form-model-item>-->

          <!--          &lt;!&ndash; 户名 &ndash;&gt;-->
          <!--          <a-form-model-item ref="custname" label="账户/钱包名" prop="custname">-->
          <!--            <WalletChooseDialog v-if="addform.accountType==='1'" @chooseDone="onWalletChosen" :scope="addform"-->
          <!--                v-model="addform.custname" style="width:200px;"></WalletChooseDialog>-->
          <!--            <a-input  v-if="addform.accountType==='2'" v-model="addform.custname" placeholder="请填写户名" />-->
          <!--          </a-form-model-item>-->
          <!--          &lt;!&ndash; 账号/钱包 &ndash;&gt;-->
          <!--          <a-form-model-item ref="accountNo" label="账号/钱包编号" prop="accountNo">-->
          <!--            <a-input v-model="addform.accountNo" placeholder="请填写账号" :disabled="addform.accountType==='1'"/>-->
          <!--          </a-form-model-item>-->
          <!--          &lt;!&ndash; 开户银行 &ndash;&gt;-->
          <!--          <a-form-model-item  v-if="addform.accountType==='2'" ref="bankNo" label="开户行号" :rules="Number(addform.accountType) == 2-->
          <!--            ? [{ required: true, message: '请填写开行号' }]-->
          <!--            : []-->
          <!--            " prop="bankNo">-->
          <!--            <a-input v-model="addform.bankNo" placeholder="请填写开行号" />-->
          <!--          </a-form-model-item>-->
          <!--          &lt;!&ndash; 开户行名称 &ndash;&gt;-->
          <!--          <a-form-model-item v-if="addform.accountType==='2'" ref="bankName" label="开户行名称" :rules="Number(addform.accountType) == 2-->
          <!--            ? [{ required: true, message: '请填写开行号名称' }]-->
          <!--            : []-->
          <!--            " prop="bankName">-->
          <!--            <a-input v-model="addform.bankName" placeholder="请填写开行号名称" />-->
          <!--          </a-form-model-item>-->
          <!--          &lt;!&ndash; 备注 &ndash;&gt;-->

          <!--          <a-form-model-item ref="note" label="备注">-->
          <!--            <a-input type="textarea" v-model="addform.note" />-->
          <!--          </a-form-model-item>-->

        </a-form-model>
      </div>
      <template v-slot:footer>

        <div class="custom-footer">
          <a-button type="primary" @click="ConfirmSave">保存</a-button>
          <a-button @click="addAnchorshow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal title="提示" class="saveloading" :maskClosable="true" @cancel="confirmshow = false" :centered="true"
      :visible="confirmshow">
      <!-- 取消 -->
      <div class="text" v-if="type == 'cancel'">
        是否取消新增？ 取消后该页面<br>
        信息将不会被保存</div>
      <!-- 新增 -->
      <div class="text" v-if="type == 'save'">
        是否确认保存店铺信息？</div>
      <template v-slot:footer>

        <div class="custom-footer" v-if="type == 'save'">
          <a-button type="primary" @click="Storesave">保存</a-button>
          <a-button @click="confirmshow = false">取消</a-button>
        </div>
        <div class="custom-footer" v-if="type == 'cancel'">
          <a-button type="primary" @click="confirmshow = false; $router.go(-1)">确认</a-button>
          <a-button @click="confirmshow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <!--  新增电商子账号-->
    <a-modal title="新增店铺子账号" class="confirmloading" :maskClosable="true" @cancel="addshopshow = false" :centered="true"
      :visible="addshopshow">
      <div class="addshopform">
        <a-form-model ref="addshopruleForm" :model="addshopform" :rules="addshoprules" :label-col="addlabelCol"
          :wrapper-col="addwrapperCol">
          <!-- 	管理账号名称 -->
          <a-form-model-item ref="shopAccountName" label="管理账号名称" prop="shopAccountName">
            <a-input v-model="addshopform.shopAccountName" placeholder="请填写管理账号名称" />
          </a-form-model-item>
          <!-- 	手机号/邮箱-->
          <a-form-model-item ref="shopMobile" label="手机号/邮箱" prop="shopMobile">
            <a-input v-model="addshopform.shopMobile" placeholder="请填写手机号/邮箱" />
          </a-form-model-item>

          <a-form-model-item ref="shopAccountPassword" label="账号密码" prop="shopAccountPassword">
            <a-input v-model="addshopform.shopAccountPassword" placeholder="账号密码" />
          </a-form-model-item>

          <!-- 	备注 -->
          <a-form-model-item ref="note" label="备注" prop="note">
            <a-input type="textarea" placeholder="请填写备注" v-model="addshopform.note" />
          </a-form-model-item>

        </a-form-model>
      </div>
      <template v-slot:footer>

        <div class="custom-footer">
          <a-button type="primary" @click="ConfirmSaveshop">保存</a-button>
          <a-button @click="addshopshow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>

import MchChooseDialog from "@/view/components/ChooseDialog/MchChooseDialog";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog";
// import { handleChannelNo } from '@/utils/commonMethods'
export default {
  components: { MchChooseDialog, WalletChooseDialog },
  data() {
    return {
      addlabelCol: { span: 8 },
      addwrapperCol: { span: 16 },
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
      title: "新增",
      treeData: [],
      confirmshow: false,
      type: 'save',
      loading: false,
      userinfo: '', // 身份证识别信息
      // mchNolist: [],// 电商列表
      addAnchorshow: false,// 添加主播弹窗
      addshopshow: false,// 添加电商子账号
      formtype: 'add',
      formshoptype: 'add',
      editingindex: '',
      editingshopindex: '',
      form: {

        departmentCode: undefined, //部门id
        mchNo: undefined, //电商编号
        mchName: "",// 电商名称
        spName: '', // 销售平台
        spNo: undefined,//销售平台编号
        shopType: '', // 店铺类型  
        autoWithdrawal: '1', // 初始值为 1
        shopName: '', //店铺名称表

        managerName: '',//  管理员姓名
        managerMobile: '',// 管理员手机号
        shopAccountPassword: '',// 管理员手机号
        appNo: "",// 	应用编号
        channelNo: "",//	渠道编号
        shopNo: '',// 店铺id
        note: "",  // 备注
        refundAccountNo: "",
        refundAccountName: "",
        collAccountNo: "",
        collAccountName: "",


      },
      mchBroadcastInfoslist: [],// 电商主播list
      mchShopManagerslist: [],// 电商店铺子账号表

      spNamelist: [],
      rules: {
        // departmentCode: [
        //   { required: true, message: '请选择所属机构', trigger: 'change' },
        // ],
        mchNo: [
          { required: true, message: '请选择商户', trigger: 'change' },
        ],
        spNo: [
          { required: true, message: '请选择销售平台', trigger: 'change' },
        ],
        // shopType: [
        //   { required: true, message: '请选择店铺类型', trigger: 'change' },
        // ],
        autoWithdrawal: [
          { required: true, message: '请选择是否自动提现', trigger: 'change' },
        ],
        shopName: [
          { required: true, message: '请输入店铺名称', trigger: 'change' },
        ],
        shopNo: [
          { required: true, message: '请输入店铺id', trigger: 'change' },
        ],
        managerMobile: [
          {
            pattern: /^1[3-9]\d{9}$/,
            message: '请输入有效的手机号码',
            trigger: 'blur',
          },
        ],
        //
      },
      // 新增主播表单
      addform: {
        custtypecd: '',// 主播达人类型
        broadcastName: '',// 主播达人昵称
        broadcastId: '',// 主播达人id
        accountType: '',//结算账户
        accountNo: "",// 钱包账号

        custname: '',// 户名
        bankNo: '',// 开户行号
        bankName: '',// 开户行名称
        note: ''


      },
      // 新增电商子账号
      addshopform: {
        shopAccountName: '',// 管理账号名称
        shopAccountPassword: '',// 管理账号名称
        shopMobile: '',// 手机号/邮箱
        note: '',
      },
      // 表单校验
      addrules: {
        custtypecd: [
          { required: true, message: '请选择达人类型', trigger: 'change' },
        ],
        broadcastName: [
          { required: true, message: '请选择主播/达人昵称', trigger: 'blur' },
        ],
        broadcastId: [
          { required: true, message: '请选择主播/达人id', trigger: 'blur' },
        ],
        // accountType: [
        //   { required: true, message: '请选择账户类型', trigger: 'change' },
        // ],
        // accountNo: [
        //   { required: true, message: '请填写账户信息', trigger: 'blur' },
        // ],
        // custname: [
        //   { required: true, message: '请填写户名', trigger: 'blur' },
        // ],
        // bankNo: [
        //   { required: true, message: '请填写开户行号', trigger: 'blur' }, {
        //     pattern: /^62\d{14}(?:\d{3})?$/,
        //     message:
        //       '请输入任意银行的银行卡号，仅支持16位或19位“62”开头的银联储蓄卡',
        //     trigger: 'blur',
        //   }
        // ],
        // bankName: [
        //   { required: true, message: '请填写开户名称', trigger: 'blur' },
        // ],

      },
      // 新增电商子账号
      addshoprules: {
        shopAccountName: [
          { required: true, message: '请输入管理账号名称', trigger: 'blur' },
        ],
        shopMobile: [
          { required: true, message: '请输入手机号或者邮箱', trigger: 'blur' },
        ],
        shopAccountPassword: [
          { required: true, message: '请输入账号密码', trigger: 'blur' },
        ],
        note: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ],
      },

      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${index + 1
            }`,
          align: 'center',
        },
        {
          title: '主播/达人类型',
          dataIndex: 'custtypecd',
          align: 'center',
          customRender: (text,) => {
            if (Number(text) == 1) {
              return '个人'
            }
            if (Number(text) == 2) {
              return '企业'
            }
          }
        },
        {
          title: '主播/达人昵称',
          dataIndex: 'broadcastName',
          align: 'center',
        },

        {
          title: '主播/达人ID',
          dataIndex: 'broadcastId',
          align: 'center',

        },
        {
          title: '操作',
          align: 'center',
          key: 'Controls',
          width: 200,
          scopedSlots: { customRender: 'Controls' },
        },
      ],
      // 	电商店铺子账号表
      rows: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${index + 1
            }`,
          align: 'center',
          width: 204
        },
        // {
        //   title: '渠道',
        //   dataIndex: 'channelNo',
        //   align: 'center',
        //   customRender: (text) => {
        //     return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
        //   },
        // },
        // {
        //   title: '电商编号',
        //   dataIndex: 'mchNo',
        //   align: 'center',
        // },

        // {
        //   title: '电商名称',
        //   dataIndex: 'mchName',
        //   align: 'center',

        // },
        // {
        //   title: '店铺编号',
        //   dataIndex: 'shopNo',
        //   align: 'center',

        // },
        // {
        //   title: '店铺名称',
        //   dataIndex: 'shopName',
        //   align: 'center',

        // },
        {
          title: '管理账号名称',
          dataIndex: 'shopAccountName',
          align: 'center',
          width: 488

        },
        {
          title: '手机号/邮箱',
          dataIndex: 'shopMobile',
          align: 'center',
          width: 488
        }, {
          title: '账号密码',
          dataIndex: 'shopAccountPassword',
          align: 'center',
          width: 488
        },
        {
          title: '备注',
          dataIndex: 'note',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          key: 'Controls',
          width: 200,
          scopedSlots: { customRender: 'Controls' },
        },
      ],
    }
  },

  async created() {
    this.form.departmentCode = sessionStorage.getItem("companyCode");
    // this.form.departmentId = sessionStorage.getItem("companyId");

    this.getsalePlatformInfolist()

    if (this.$route.query.type === "add") {
      this.title = ' 新增'
    } else if (this.$route.query.type == "edit") {
      this.getMchShopDetails()
      this.title = ' 编辑'
    } else if (this.$route.query.type == "view") {
      this.title = ' 查看'
      this.getMchShopDetails()
    }

    // 加载选中客户信息
    if (this.$route.params.type === "add") {
      this.onCustChosen({ custnum: this.$route.params.custnum })
    }
  },
  mounted() {
    if (this.$route.query.type == 'edit' || this.$route.query.type == 'view') {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  methods: {

    onRefundWalletChosen(wallet) {

      this.form.refundAccountNo = wallet.accountNo
      this.form.refundAccountName = wallet.accountName
    },

    onCollWalletChosen(wallet) {

      this.form.collAccountNo = wallet.accountNo
      this.form.collAccountName = wallet.accountName
    },


    // 选中电商
    onMchChosen(mch) {
      this.form.departmentId = mch.departmentId
      this.form.channelNo = mch.channelNo
      this.form.mchName = mch.mchName
      this.form.mchNo = mch.mchNo
      this.form.appNo = mch.appNo
      this.form.shopType = mch.custtypecd == '1' ? '0' : '1'  //0个人，1企业    融资电商钱包类型：1个人，2企业
    },
    // 保存 主播保存
    async ConfirmSave() {
      // 校验变表单
      this.$refs.addruleForm.validate((valid) => {
        if (valid) {
          // 校验通过将数据手动添加到电商数组里
          if (this.formtype == 'add') {
            this.mchBroadcastInfoslist.push(this.addform)

          } else if (this.formtype == 'edit') {
            this.$set(this.mchBroadcastInfoslist, this.editingindex, this.addform)
          }

          this.addform = {
            custtypecd: '',// 主播达人类型
            broadcastName: '',// 主播达人昵称
            broadcastId: '',// 主播达人id
            accountType: '',//结算账户
            accountNo: "",// 钱包账号
            custname: '',// 户名
            bankNo: '',// 开户行号
            bankName: '',// 开户行名称
            note: ''
          }
          this.addAnchorshow = false
        } else {
          this.$message.warning('请先填写主播信息')
        }
      })
    },
    // 保存电子子账号
    async ConfirmSaveshop() {
      // 校验变表单
      this.$refs.addshopruleForm.validate((valid) => {
        if (valid) {
          // 校验通过将数据手动添加到电商数组里
          if (this.formshoptype == 'add') {
            this.mchShopManagerslist.push(this.addshopform)

          } else if (this.formshoptype == 'edit') {
            this.$set(this.mchShopManagerslist, this.editingshopindex, this.addshopform)
          }

          this.addshopform = {
            shopAccountName: '',// 管理账号名称
            shopAccountPassword: '',// 管理账号名称
            shopMobile: '',// 手机号/邮箱
            note: '',
          },
            this.addshopshow = false
        } else {
          this.$message.warning('请先填写店铺信息')
        }
      })
    },
    // 选择电商触发
    mchNochange(value, option) {
      this.form.appNo = option.data.attrs.appNo
      this.form.channelNo = option.data.attrs.channelNo
      this.form.mchName = option.data.attrs.label


    },
    // 选择销售平台
    spNochange() {
      this.form.spName = this.spNamelist.find(sp => sp.value === this.form.spNo)?.label
    },
    // 获取店铺管理详情
    async getMchShopDetails() {

      const res = await this.api.getMchShopDetailsApi(this.$route.query.id)
      if (res.code !== 200) {

        return this.$message.error(res.info)
      }
      console.log(res);
      this.form = res.data
      if (res.data.mchBroadcastInfos) {
        this.mchBroadcastInfoslist = res.data.mchBroadcastInfos
      }

      if (res.data.mchShopManagers) {
        this.mchShopManagerslist = res.data.mchShopManagers
      }
    },
    // 店铺确认提示弹窗
    confirm() {
      // 表单校验
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.type = 'save'
          this.confirmshow = true
        } else {
          this.$message.warning('请先填店铺基础信息')
        }
      })
    },
    // 确认新增店铺
    async Storesave() {
      // 处理数据 如果是新增
      let data = JSON.parse(JSON.stringify(this.form))
      data.mchBroadcastInfos = this.mchBroadcastInfoslist
      data.mchShopManagers = this.mchShopManagerslist
      const res = await this.api.addOrUpdateApi(data)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.$message.success('新增成功')
      let query = {}
      if (this.$route.query.type === 'add') {
        query.fromAddPage = true
      } else if (this.$route.query.type === 'edit') {
        query.fromEditPage = true
      }
      this.$router.push({
        path: '/Mch/MchShopManage',
        query
      })
    },
    // 编辑主播
    Editing(item, index) {
      console.log(item, index);
      this.addAnchorshow = true
      let obj = JSON.parse(JSON.stringify(item))
      this.addform = obj
      this.formtype = 'edit'
      this.editingindex = index
    },
    // 删除主播
    Deleterow(item, index) {
      var that = this;
      this.$confirm({
        title: '是否删除该条数据?',
        okText: '确认',
        cancelText: '取消',
        centered: true,
        onCancel() {
        }, onOk() {
          if (that.$route.query.type == 'add') {
            that.mchBroadcastInfoslist.splice(index, 1)
          } else {
            if (item.id) {
              // 发请求删除 完后，手动删除
              let arr = []
              arr.push(item.id)
              that.api.broadcastdeleteApi(arr).then((res) => {
                if (res.code !== 200) {

                  return that.$message.error(res.info)
                }
                that.$message.success('删除成功')
                that.mchBroadcastInfoslist.splice(index, 1)
              })

            } else {
              that.mchBroadcastInfoslist.splice(index, 1)
            }
          }
        },
      });

    },

    // 编辑电商子账号
    Editshoping(item, index) {
      this.addshopshow = true
      let obj = JSON.parse(JSON.stringify(item))
      this.addshopform = obj
      this.formshoptype = 'edit'
      this.editingshopindex = index
    },
    // 删除电商子账号
    Deleteshoprow(item, index) {
      var that = this;
      this.$confirm({
        title: '是否删除该条数据?',
        okText: '确认',
        cancelText: '取消',
        centered: true,
        onCancel() {
        }, onOk() {
          if (that.$route.query.type == 'add') {
            that.mchShopManagerslist.splice(index, 1)
          } else {
            if (item.id) {
              // 发请求删除 完后，手动删除
              let arr = []
              arr.push(item.id)
              that.api.mchbroadcastdeleteApi(arr).then((res) => {
                if (res.code !== 200) {

                  return that.$message.error(res.info)
                }
                that.$message.success('删除成功')
                that.mchShopManagerslist.splice(index, 1)
              })

            } else {
              that.mchShopManagerslist.splice(index, 1)
            }
          }
        },
      });

    },


    // 新增主播
    addAnchor() {
      this.formtype = 'add'
      this.addAnchorshow = true
    },
    addsubaccount() {
      this.formshoptype = 'add'
      this.addshopshow = true
    },
    // 新增电商子账号
    // 获取销售平台列表
    async getsalePlatformInfolist() {
      const res = await this.api.salePlatformInfolistApi()
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.spNamelist = []
      res.data.forEach((item) => {
        this.spNamelist.push({
          label: item.spName,
          value: item.spNo
        })
      })

    },

  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>

<style lang="less" scoped>
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

/deep/ .ant-modal-header {
  height: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 700;

}

.saveloading {
  /deep/ .ant-modal-footer {
    border: none;
  }

  .text {
    text-align: center;
  }
}

.Controlsbox {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;

    &:first-child {

      color: #57c6f4,
    }

    &:last-child {
      margin-left: 20px;
      color: #d9001b,
    }
  }
}

/deep/.ant-form-item-label {
  text-align: left;
}

.addform {


  /deep/.ant-form-item {
    margin-bottom: 10px;
  }
}

/deep/.ant-modal-close-x {
  height: 40px;
  line-height: 40px;
}

/deep/.ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

/deep/.ant-modal-content {
  width: 420px;
}

.formbox {
  .toptitle {
    font-size: 15px;
    font-weight: 700;
    height: 38px;
    color: #262626;
  }
}

.Anchor {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .ant-tag {
      cursor: pointer;
    }
  }

}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      &:last-child {
        font-size: 15px;
      }
    }
  }

  .right {
    button {
      height: 38px;
      border: none;

      &:first-child {
        width: 108px;

        color: #ffff;
      }

      &:last-child {
        width: 108px;
        background-color: #d7d7d7;
        color: #ffff;
      }
    }
  }
}

.Electronicbusiness {
  padding-bottom: 100px;

  .departmentId {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }


  /deep/ .ant-form-item-label {
    min-height: 45px;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  // .channel_no {
  //   // /deep/ .ant-form-item-label {
  //   //   padding-top: 10px;

  //   // }
  // }

  /deep/.ant-input-suffix {
    right: 0;
    width: 40px;
  }



  /deep/ .ant-form-item {
    background-color: #f2f2f2;
    min-height: 50px;
    margin-bottom: 0px !important;
  }

  /deep/ .ant-form-item-control-wrapper {
    padding-left: 35px;
    padding-left: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;
    border-left: none;
    display: flex;
    min-height: 50px;
    align-items: center;

    .ant-form-item-control {
      min-width: 200px;
    }
  }


}
</style>
